(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.reviewOrder")
        .controller("ReviewOrder", ReviewOrder);

    ReviewOrder.$inject = ["$scope", "navigate", "shoppingCart", "site", "notify", "api", "NAV_URLS", "FULFILLMENT_TYPES"];

    function ReviewOrder($scope, navigate, shoppingCart, site, notify, api, NAV_URLS, FULFILLMENT_TYPES) {
        var model = this;

        model.submitOrder = submitOrder;
        model.cancelOrder = cancelOrder;
        model.orderItem = shoppingCart.item();
        model.currencySymbol = site.currencySymbol;
        model.site = site;
        model.submitDisabled = false;
        model.totals = shoppingCart.getOrderTotals(model.orderItem);
        $scope.NAV_URLS = NAV_URLS;
        model.FULFILLMENT_TYPES = FULFILLMENT_TYPES;
        model.captchaResponse = null;
        model.key = '6LfeEZAiAAAAAJow4_a9K9X6QgWgKv9k2EopTuZh';

        if (!shoppingCart.cardProductsAndAmountsValid()) {
			navigate.toSalesHome();
		}
		if (!shoppingCart.shippingInfoValid()) {
            navigate.toOrderDetails();
		}
		if (!shoppingCart.paymentAndBillingInfoValid() && !model.orderItem.isCorporate) {
            navigate.toOrderDetails();
        }
        
        function submitOrder() {
            if (site.amlLimit === 0) {
                site.amlLimit = 2000;//This is a temp fix will address very soon.
            }
            var orderTotal = _.reduce(model.orderItem.cardAmounts, function (memo, amount) { return memo + (amount.amount * amount.quantity); }, 0);
			//one last aml limit check
			var isBelowAmlLimit = orderTotal < site.amlLimit;
			if (isBelowAmlLimit || model.orderItem.isCorporate) {
                model.submitDisabled = true;                
				shoppingCart.submitOrder(site, $scope.currentLanguage().key, model.captchaResponse)
                .then(function () {
					model.submitDisabled = false;
					navigate.toOrderConfirmation();
                }, function () {
                    if (model.orderItem.disbursementAccountId) {
                        return notify.forHttp(api.order.deleteDisbursementAccount(model.orderItem.disbursementAccountId), { startTranslationId: "PROGRESS_MESSAGES.REMOVING_DISBURSEMENT_ACCOUNT" })
                            .then(function (data) {
                                model.submitDisabled = false;
                            });
                    }
                    model.submitDisabled = false;
				});
			}
        }

        function cancelOrder() {
            //if corp then clean up funds disbursement account and reference number.
            if (model.orderItem.isCorporate) {
                return notify.forHttp(api.order.deleteDisbursementAccount(model.orderItem.disbursementAccountId), { startTranslationId: "PROGRESS_MESSAGES.REMOVING_DISBURSEMENT_ACCOUNT" })
                    .then(function (data) {
                        shoppingCart.reset();
                        navigate.toHome();
                    });
            } else {
                shoppingCart.reset();
                navigate.toHome();
            }
        }
	}
}());