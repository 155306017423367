(function () {
	"use strict";

	angular.module("angular.emlpayments")
        .filter("digitsOnly", [function () {
        	return function (input) {
        		if (input) {
        			return input.replace(/\D/g, "");
        		}
        	};
        }]);
}());