(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.directives")
		.directive("sfContactInfo", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				contact: "=",
				collectEmail: "=",
				collectPhone: "=",
				collectBusinessName: "=",
				requiredFields: "=",
				defaultCountryNumericCode: "=",
				content: "=",
				labelTranslationId: "=?",
				showLabel: "=?",
				applyRegionRestrictions: "=?",
				countriesEnabled: "=?",
				pickupInstructionsTranslationId: "=?",
				site: "="
			},
			controller: Controller,
			controllerAs: "model",
			templateUrl: function(elem, attr) {
				return "areas/directives/contactInfo." + attr.type + ".directive.html";
			}
		};
	}

	Controller.$inject = ["$scope", "$rootScope", "lookupValues", "CONTACT_FIELDS", "shoppingCart", "FULFILLMENT_TYPES", "EVENTS"];

	function Controller($scope, $rootScope, lookupValues, CONTACT_FIELDS, shoppingCart, FULFILLMENT_TYPES, EVENTS) {
		var model = this;
		var countryList;

		model.isRequired = isRequired;
		model.CONTACT_FIELDS = CONTACT_FIELDS;
		model.FULFILLMENT_TYPES = FULFILLMENT_TYPES;
		model.regions = [];
		model.applyRegionRestrictions = applyRegionRestrictions;
		model.showLabel = $scope.showLabel;
		model.labelTranslationId = $scope.labelTranslationId;
		model.orderItem = shoppingCart.item();
		model.isCorporateSale = model.orderItem.isCorporate;
		model.pickupInstructionsTranslationId = $scope.pickupInstructionsTranslationId;
		model.content = $scope.content;
		model.site = $scope.site;

		init();

		function init(callback) {
			var cultureCode = $rootScope.content.cultureCode;

			lookupValues.getCountries(cultureCode)
				.then(function (data) {
					countryList = data;
					ensureContactCountry();
					setRegionsForCountry();
					if (angular.isFunction(callback)) {
						callback();
					}
				});
		}

		$scope.$watch("countriesEnabled", function () {
			if (!angular.isArray(countryList)) {
				init(function () {
					setCountriesEnabled();
				});
			}
			else {
				setCountriesEnabled();
			}
		});

		function setCountriesEnabled() {
			if (angular.isArray($scope.countriesEnabled)) {
				model.countries = _.filter(countryList, function (country) {
					return _.find($scope.countriesEnabled, function (enabledCountry) {
						return enabledCountry.countryNumericCode === country.numericCode;
					});
				});
			}
			else {
				model.countries = countryList;
			}
			ensureContactCountry();
			setRegionsForCountry();
		}

		function applyRegionRestrictions() {
			if (angular.isFunction($scope.applyRegionRestrictions)) {
				return $scope.applyRegionRestrictions();
			}
			return $scope.applyRegionRestrictions;
		}

		function isRequired(fieldName) {
			return $scope.requiredFields && _.find($scope.requiredFields, function(field) {
				return field === fieldName;
			});
		}

		model.countrySelectionChanged = countrySelectionChanged;

		function countrySelectionChanged() {
			$scope.contact.postalCode = undefined;
			$scope.contact.state = undefined;
			$scope.contact.province = undefined;
			setRegionsForCountry();
		}

		function setRegionsForCountry() {
			if ($scope.contact && $scope.contact.country) {
				var cultureCode = $rootScope.content.cultureCode;

				lookupValues.getRegions($scope.contact.country.numericCode, cultureCode)
					.then(function (regions) {
						model.regions = _.sortBy(regions, function (region) { return region.description; });
						model.regionsRestricted = _.reject(model.regions, function (region) { return region.isExcluded; });
						model.showRegionSelection = $scope.contact.country && hasRegions();
					});
			}
		}

		function ensureContactCountry() {
			if ($scope.contact && !$scope.contact.country && $scope.defaultCountryNumericCode) {
				var defaultCountry = _.find(model.countries, function(country) {
					return country.countryNumericCode === $scope.defaultCountryNumericCode;
				});
				if (defaultCountry) {
					$scope.contact.country = angular.copy(defaultCountry);
				}
			}
		}

		function hasRegions() {
			return angular.isArray(model.regions) && model.regions.length > 0;
		}

		$scope.$watch("contact", function () {
			init();
		});

		$rootScope.$watch("content.cultureCode", function(newValue, oldValue) {
			init();
		}, true);

		$scope.phonePattern = (function () {
			var regex = model.isCorporateSale ? /^(\d[- ]?){9,20}$/ : /^(\d[- ]?){10,20}$/;
			return {
				test: function (value) {
					return regex.test(value)
				}
			}
		})();

		$rootScope.$on(EVENTS.FULFILLMENT_INFO_CHANGED,
		    function() {
			    if (model.orderItem.selectedFulfillmentType) {
				    model.selectedFulfillmentTypeId = model.orderItem.selectedFulfillmentType.id;
			    }

			    model.selectedFulfillmentTypeIsShipping = model.orderItem.selectedFulfillmentType &&
				    model.orderItem.selectedFulfillmentType.id === FULFILLMENT_TYPES.SHIPPING.ID;
		    });
	}
}());
