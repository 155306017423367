(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.lockedCard")
        .controller("LockedCard", LockedCard);

    LockedCard.$inject = [];

    function LockedCard() {
        var model = this;        
    }
}());