(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.check-balance.directives")
        .directive("sfCardInfo", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                site: "=",
                card: "=",
                content: "=",
                cardRegistrationText: "=?",
                showRegistrationText: "=?"
            },
            controller: Controller,
            controllerAs: "model",
            templateUrl: "areas/check-balance/directives/card-info.directive.html"
        };
    }

    Controller.$inject = ["$scope", "$sce"];

    function Controller($scope, $sce) {
        var model = this;

        model.site = $scope.site;
        model.card = $scope.card;
        model.content = $scope.content;
        model.showRegistrationText = $scope.showRegistrationText;
        model.cardRegistrationText = $scope.cardRegistrationText;
        model.showCardNumber = false;
        model.toggleDisplayCardNumber = toggleDisplayCardNumber;

        function toggleDisplayCardNumber() {
            model.showCardNumber = !model.showCardNumber;
        }
    }

}());