(function () {
    "use strict";

	angular.module("cardFrontConsumer")
    .config(configHome);

    configHome.$inject = ["$routeProvider", "NAV_URLS"];
    function configHome($routeProvider, NAV_URLS) {

                $routeProvider
                .when(NAV_URLS.HOME, {
                	templateUrl: "areas/home/home.html",
                	controller: "Home",
                	controllerAs: "model",                	
                	resolve: {
                		 site: resolveSite
                	}

                })
                .otherwise({ redirectTo: NAV_URLS.HOME });
    }

    resolveSite.$inject = ["siteService"];

    function resolveSite(siteService) {
    	return siteService.get();
    }


}());