(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.orderConfirmation")
        .controller("OrderConfirmation", OrderConfirmation);

	OrderConfirmation.$inject = ["$scope", "$rootScope", "navigate", "shoppingCart", "site", "api", "FileSaver", "Blob", "notify", "$translate"];

	function OrderConfirmation($scope, $rootScope, navigate, shoppingCart, site, api, FileSaver, Blob, notify, $translate) {
		var model = this;        
		model.site = site;
		model.orderItem = shoppingCart.getSubmittedOrder();
        model.currencySymbol = site.currencySymbol;        
        model.returnHome = returnHome;
        model.totals = shoppingCart.getOrderTotals(model.orderItem);
        model.content = $scope.content;
		if (!model.orderItem) {
			navigate.toSalesHome();
		}

        model.placeAnotherOrder = placeAnotherOrder;
        model.modalShown = false;
        model.showModal = showModal;
        model.SaveContactThenPlaceAnotherOrder = saveContactThenPlaceAnotherOrder;
        model.currencySymbol = site.currencySymbol;
        model.corporateInvoicingEnabled = site.corporateInvoicingEnabled;
        model.getOrderInvoice = getOrderInvoice;
        
        function getOrderInvoice() {
            notify.forHttp(api.order.getInvoice(model.orderItem.orderId, $rootScope.currentLanguage().key), {
                    startTranslationId: "PROGRESS_MESSAGES.GETTING_INVOICE"
                })
                .then(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    FileSaver.saveAs(blob, getInvoiceFileName());
                });
        }

        function getInvoiceFileName() {
            return $translate.instant("ORDER_CONFIRMATION.INVOICE_FILE_NAME", {orderId: model.orderItem.orderId}) || 'invoice-' + model.orderItem.orderId + '.pdf';
        }

		function placeAnotherOrder() {
			model.modalShown = false;
			shoppingCart.reset();
			navigate.toSalesHome();
        }

		function saveContactThenPlaceAnotherOrder() {
			model.modalShown = false;
            shoppingCart.reset();
            shoppingCart.setPurchaserInfo();
            navigate.toSalesHome();
        }

		function returnHome() {			
            shoppingCart.reset();
            navigate.toHome();
        }

        function showModal() {
            model.modalShown = !model.modalShown;
        }        
	}

}());