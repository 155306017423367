(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(config);

	config.$inject = ["$routeProvider", "NAV_URLS"];

	function config($routeProvider, NAV_URLS) {

		$routeProvider
            .when(NAV_URLS.CONTACT, {
            	templateUrl: "areas/contact/contact.html",
            	controller: "Contact",
            	controllerAs: "model",
            	titleTranslationId: "CONTACT.TITLE",
            	resolve: {
            		site: resolveSite
            	}
            });
	}

	resolveSite.$inject = ["siteService"];

	function resolveSite(siteService) {
		return siteService.get();
	}

}());