(function () {
    "use strict";

	angular.module("cardFrontConsumer")
        .factory("globalHttpInterceptor", globalHttpInterceptor);

    globalHttpInterceptor.$inject = ["$rootScope", "apiUrlFormatter"];

    function globalHttpInterceptor ($rootScope, apiUrlFormatter) {
        return {
            "request": function (config) {
                var acceptLanguage = "Accept-Language";

                if (!(config.url.startsWith("http") || config.url.endsWith(".json") || config.url.endsWith(".html"))) {
                    //convenient way to format all $http URLs outside of static files and calls to the oauth service
                    config.url = apiUrlFormatter.format(config.url);
                }

                if(!config.headers[acceptLanguage]){                                                            
                    config.headers[acceptLanguage] = $rootScope.currentLanguage().key;
                }

                return config;
            }
        };
    }


}());