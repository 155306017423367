(function () {
    "use strict";
    angular.module("cardFrontConsumer")
        .config(configChangePin);

    configChangePin.$inject = ["$routeProvider", "NAV_URLS"];

    function configChangePin($routeProvider, NAV_URLS) {
        $routeProvider
            .when(NAV_URLS.CHANGE_PIN,
                {
                    templateUrl: "areas/check-balance/change-pin/change-pin.html",
                    controller: "ChangePin",
                    controllerAs: "model",
                    resolve: {
                        site: resolveSite
                    }
                })
            .otherwise({ redirectTo: NAV_URLS.HOME });
    }

    resolveSite.$inject = ["siteService"];

    function resolveSite(siteService) {
        return siteService.get();
    }
}());