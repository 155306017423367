(function() {
	"use strict";

	angular.module("cardFrontConsumer")
        .run(["$rootScope", "$translate", "siteService", "notify", "NAV_URLS", "PAGE_TITLE_INTERPOLATION_EXPRESSION", "DATE_FORMATS", "DEFAULT_DATE_FORMAT", "store", "STORE_KEYS", "$location", "FOOTER_URLS",
            function ($rootScope, $translate, siteService, notify, NAV_URLS, PAGE_TITLE_INTERPOLATION_EXPRESSION, DATE_FORMATS, DEFAULT_DATE_FORMAT, store, STORE_KEYS, $location, FOOTER_URLS) {
                $rootScope.NAV_URLS = NAV_URLS;
                $rootScope.FOOTER_URLS = FOOTER_URLS;
				$rootScope.currentYear = new Date().getFullYear();
				$rootScope.useLanguage = function (cultureCode) {
				    if (cultureCode) {
				        $translate.use(cultureCode);
					}
				};
                
				$rootScope.currentLanguageTranslationId = "";
                $rootScope.content = {};
                $rootScope.checkBalanceContent = {};
                $rootScope.consumerSalesContent = {};
                setEmlPrivacyPolicy();
                $rootScope.contentLoaded = false;
                $rootScope.onNavLinkClicked = onNavLinkClicked;
                setIsTrailingSymbol();
                setCurrencySeparators();
                
                $rootScope.$on("$translateChangeSuccess", function (event, args) {
					if (args.language) {
						setCurrentLanguageTranslationId(args.language);
                        setContent(args.language);                        
						$rootScope.selectedLanguageNameTranslationId = "CULTURES." + formatLang(args.language);
                        setBrowserTitle();
                        setEmlPrivacyPolicy();
                        setIsTrailingSymbol();
                        setCurrencySeparators();
					}
					
                });
                function formatLang(lang)
                {
                    if (lang.indexOf("-") == -1)
                    {
                        return lang;
                    }
                    // make sure there is something after the dash
                    if (lang.indexOf("-") >= lang.length-1)
                    {
                        return lang;
                    }

                    var prefix = lang.substring(0, lang.indexOf("-"));
                    var suffix = lang.substring(lang.indexOf("-")+1, lang.length);
                    
                    return prefix.toLowerCase() + "_" + suffix.toUpperCase();

                }
                function setCurrencySeparators() {
                    $rootScope.currencySeparators = {
                        GROUP_SEP: $translate.currentLanguage().currencyFormat.groupSeparator,
                        DECIMAL_SEP: $translate.currentLanguage().currencyFormat.decimalSeparator
                    };
                }

                function onNavLinkClicked($event) {
                    document.querySelector('.navbar-toggle').click();
                    window.scrollTo(0, 0);
                }

                function setIsTrailingSymbol() {
                    $rootScope.isTrailingSymbol = $translate.currentLanguage().currencyFormat.isTrailingSymbol;
                }

                function setEmlPrivacyPolicy() {
                    $rootScope.emlPrivacyPolicyUrl = $translate.currentLanguage().privacyPolicyUrl;
                }

				function setCurrentLanguageTranslationId(cultureCode) {
					if (cultureCode) {
						$rootScope.currentLanguageTranslationId = "CULTURES." + cultureCode.replace("-", "_");
					}
					return "";
				}

				function setContent(cultureCode) {
					if (cultureCode) {
						siteService.get().then(function(site) {
							var content = _.find(site.siteVersionCultures, function(siteVersionCulture) {
								return siteVersionCulture.cultureCode.toLowerCase() === cultureCode.toLowerCase();
							});
							if (content) {
								$rootScope.content = content;
                                $rootScope.contentLoaded = true;
								$rootScope.hasFaq = content.faq;
								$rootScope.content.browserTitle = "";
                                $rootScope.binIssuanceStatement = content.bankIssuanceStatement || (site ? site.binIssuanceStatement : "");
                                $rootScope.consumerSalesContent = getConsumerSalesContent(site, cultureCode);
                                $rootScope.checkBalanceContent = getCheckBalanceContent(site, cultureCode);
							    $rootScope.cardLookup.programUniqueTag = site.programUniqueTag;
							}
						});
					}
                }

                function getConsumerSalesContent(site, cultureCode) {
                    return _.find(site.consumerSalesSiteVersionCultures, function(consumerSalesSiteVersionCulture) {
                        return consumerSalesSiteVersionCulture.cultureCode.toLowerCase() === cultureCode.toLowerCase();
                    });
                }

                function getCheckBalanceContent(site, cultureCode) {
                    return _.find(site.checkBalanceSiteVersionCultures, function (checkBalanceSiteVersionCulture) {
                        return checkBalanceSiteVersionCulture.cultureCode.toLowerCase() === cultureCode.toLowerCase();
                    });
                }
                
				$rootScope.$on("$routeChangeSuccess", function(event, nextRoute) {
					if (nextRoute.$$route) {
						notify.dismissErrorAlert();

						if (nextRoute.$$route.titleTranslationId) {
							$rootScope.titleTranslationId = nextRoute.$$route.titleTranslationId;
							setBrowserTitle();
						} else {
							$rootScope.content.browserTitle = "";
						}
					}
				});

				function setBrowserTitle() {
					$translate($rootScope.titleTranslationId).then(function (pageTitle) {
						if ($rootScope.content.browserTitleFormatString) {
							$rootScope.content.browserTitle = $rootScope.content.browserTitleFormatString.replace(PAGE_TITLE_INTERPOLATION_EXPRESSION, pageTitle);
						} else {
							$rootScope.content.browserTitle = pageTitle;
						}
					});
				}

				$rootScope.currentLanguage = function() {
					return $translate.currentLanguage();
                };

                function currentDateFormat() {
                    var currentLang = $translate.currentLanguage(),
                        formatName = currentLang ? currentLang.dateFormat : DEFAULT_DATE_FORMAT;
                        return _.find(DATE_FORMATS, function (dateFormat) {
                            return dateFormat.name === formatName;
                        });                    
                }

			    $rootScope.currentDateFormatString = function() {
			        var format = currentDateFormat();
			        if (format) {
			            return format.formatString;
			        }
                    throw "The date format string for {formatName} could not be found.".supplant({ formatName: $translate.currentLanguage() });
                }

                $rootScope.currentDatemonthYearFormatString = function () {
                    var format = currentDateFormat();
                    if (format) {
                        return format.monthYearFormatString;
                    }
                    throw "The month year format string for {formatName} could not be found.".supplant({ formatName: $translate.currentLanguage() });
                }
                
                $rootScope.modalShown = false;
                $rootScope.closeModal = closeModal;
                $rootScope.showModal = showModal;
                $rootScope.getCard = getCard;                
                $rootScope.cardLookup = {
                    printText: "",
                    securityCode: "",
                    errorMessage: "",
                    programUniqueTag: null
                };
                $rootScope.clearCardLookup = clearCardLookup;

                function clearCardLookup() {
                    $rootScope.cardLookup.printText = "";
                    $rootScope.cardLookup.errorMessage = "";
                    $rootScope.cardLookup.securityCode = "";
                }

                function showModal() {
                    $rootScope.modalShown = !$rootScope.modalShown;
                }

                function closeModal(isValid) {
                    $rootScope.modalShown = !isValid;
                }

                $rootScope.setCard = function(card) {
                    store.set(STORE_KEYS.CARD, card);                    
                }

                $rootScope.removeCard = function () {
                    store.remove(STORE_KEYS.CARD);
                }

                function getCard() {                    
                    return  store.get(STORE_KEYS.CARD);                    
                }

                function isHomePage() {
                    return $location.path().length === 1;
                }

                $rootScope.isHomePage = function () {
                    return isHomePage();
                }
			}
		]);
}());