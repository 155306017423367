(function () {
    "use strict";

    angular.module("angular.emlpayments")
		.factory("lookupValues", lookupValuesFactory);

    lookupValuesFactory.$inject = ["$q", "api", "notify"];

	function lookupValuesFactory($q, api, notify) {
		var lockCardReasonsPromise,
            salesChannelsPromise,
            countryPromise,
			countriesPromise,
            regionCollections = [],
            cardUseTypesPromise,
			identificationSourceTypesPromise,
			getCountriespreviousCulture;


		return {
			getLockCardReasons: getLockCardReasons,
            getSalesChannels: getSalesChannels,
            getCountry: getCountry,
			getCountries: getCountries,
            getRegions: getRegions,
            getCardUseTypes: getCardUseTypes,
            getIdentificationSourceTypes: getIdentificationSourceTypes
		};

		function getLockCardReasons() {

			if (!lockCardReasonsPromise) {
				lockCardReasonsPromise = api.lookupValues.getLockCardReasons();
			}
			
			return lockCardReasonsPromise;
		}

		function getSalesChannels() {
			if (!salesChannelsPromise) {
				salesChannelsPromise = notify.forHttp(api.activation.getSalesChannels(), { startTranslationId: "LOOKUP_VALUES.GET_SALES_CHANNELS_PROGRESS_TEXT" });
			}
			return salesChannelsPromise;
		}

		function getCountry(country) {
                return notify.forHttp(api.lookupValues.getCountry(country), { startTranslationId: "LOOKUP_VALUES.GET_COUNTRY_PROGRESS_TEXT" });
        }

	    function getCountries(cultureName) {

	        if (!countriesPromise || getCountriespreviousCulture !== cultureName) {
				countriesPromise = notify.forHttp(api.lookupValues.getCountries(cultureName), { startTranslationId: "LOOKUP_VALUES.GET_COUNTRIES_PROGRESS_TEXT" });
				getCountriespreviousCulture = cultureName;
			}
			
	        return countriesPromise;
		}

        function getCardUseTypes() {
            if (!cardUseTypesPromise) {
                cardUseTypesPromise = notify.forHttp(api.lookupValues.getCardUseTypes(), { startTranslationId: "LOOKUP_VALUES.GET_CARD_USE_TYPES" })
            }
            return cardUseTypesPromise;
        }

        function getIdentificationSourceTypes() {
            if (!identificationSourceTypesPromise) {
                identificationSourceTypesPromise = notify.forHttp(api.lookupValues.getIdentificationSourceTypes(), { startTranslationId: "LOOKUP_VALUES.GET_IDENTIFICATION_SOURCE_TYPES"})
            }
            return identificationSourceTypesPromise;
        }
		
		function getRegions(countryKey, cultureName) {

			var deferred = $q.defer();
			
			notify.forHttp(api.lookupValues.getRegions(countryKey, cultureName), { startTranslationId: "LOOKUP_VALUES.GET_REGIONS_PROGRESS_TEXT" })
			.then(function (data) {
					var newCountryRegion = {
						countryKey: countryKey,
						regions: data
					};
					regionCollections.push(newCountryRegion);
					deferred.resolve(newCountryRegion.regions);

				}, function(error) {
					deferred.reject(error);
				});

			return deferred.promise;
		}
	}
} ());