(function () {
    "use strict";

    angular.module("cardFrontConsumer", [
    "ngRoute",
    "ngSanitize",
    "ngCookies",
    "ngModal",
    "currencyFilter",
    "ngFileSaver",

    "pascalprecht.translate",
    "angular-storage",
    "angular.emlpayments",    
    "credit-cards",
    "vcRecaptcha",   

	"cardFrontConsumer.areas.global",
	"cardFrontConsumer.areas.home",							
	"cardFrontConsumer.areas.contact",
	"cardFrontConsumer.areas.faqs",
	"cardFrontConsumer.areas.enterPin",
	"cardFrontConsumer.areas.termsOfUse",
    "cardFrontConsumer.areas.privacyPolicy",
    "cardFrontConsumer.areas.directives",
    "cardFrontConsumer.areas.lockedCard",    

    "cardFrontConsumer.areas.cardholderAgreement",
    "cardFrontConsumer.areas.sales.directives",
    "cardFrontConsumer.areas.sales.sales-home",
    "cardFrontConsumer.areas.sales.reviewOrder",
    "cardFrontConsumer.areas.sales.orderConfirmation",
    "cardFrontConsumer.areas.sales.order-details",

    "cardFrontConsumer.areas.check-balance.check-balance-home",
    "cardFrontConsumer.areas.check-balance.register-card",
    "cardFrontConsumer.areas.check-balance.directives",
    "cardFrontConsumer.areas.check-balance.change-pin",

	"cardFrontConsumer.services"
    ]);
}());
