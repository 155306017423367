(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
		.directive("sfPaymentInfo", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
                paymentInformation: "=",
                site: "=?",
                orderItem: "=?"
			},
			controller: Controller,
			controllerAs: "model",
			templateUrl: function(elem, attr) {
				return "areas/sales/directives/paymentInfo." + attr.type + ".directive.html";
			}
		};
	}

	Controller.$inject = ["$scope"];

	function Controller($scope) {
        var model = this;

        model.site = $scope.site;
		model.orderItem = $scope.orderItem;
		model.creditCardValid = true;
		model.onCreditCardInputBlur = onCreditCardInputBlur;
		model.onCreditCardInputFocus = onCreditCardInputFocus;

		function onCreditCardInputBlur(creditCardInput) {
			model.creditCardValid = !creditCardInput.$error.ccNumber;
		}

		function onCreditCardInputFocus() {
			model.creditCardValid = true;
		}
	}
}());
