(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(config);

	config.$inject = ["$routeProvider", "NAV_URLS"];

	function config($routeProvider, NAV_URLS) {

		$routeProvider
            .when(NAV_URLS.ORDER_CONFIRMATION, {
                templateUrl: "areas/sales/order-confirmation/order-confirmation.html",
            	controller: "OrderConfirmation",
            	controllerAs: "model",
            	titleTranslationId: "ORDER_CONFIRMATION.TITLE",
            	resolve: {
            		site: resolveSite
            	}	
            });
	}
	resolveSite.$inject = ["siteService"];

	function resolveSite(siteService) {
		return siteService.get();
	}


}());