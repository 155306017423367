(function () {
    "use strict";

    angular.module("cardFrontConsumer")
        .config(config);

    config.$inject = ["$routeProvider", "NAV_URLS"];

    function config($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.LOCKED_CARD, {
                templateUrl: "areas/locked-card/locked-card.html",
                controller: "LockedCard",
                controllerAs: "model",
                titleTranslationId: "LOCKED_CARD.TITLE",
                resolve: {
                    site: resolveSite
                }
            });

        resolveSite.$inject = ["siteService"];

        function resolveSite(siteService) {
            return siteService.get();
        }
    }
}());