(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(config);

	config.$inject = ["$routeProvider", "NAV_URLS"];

	function config($routeProvider, NAV_URLS) {

		$routeProvider
            .when(NAV_URLS.TERMS_OF_USE, {
            	templateUrl: "areas/terms-of-use/terms-of-use.html",
            	controller: "TermsOfUse",
            	controllerAs: "model",
            	titleTranslationId: "TERMS_OF_USE.TITLE",
            	resolve: {
            		site: resolveSite
            	}
            });

		resolveSite.$inject = ["siteService"];

		function resolveSite(siteService) {
			return siteService.get();
		}
	}
}());