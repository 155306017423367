(function () {
	"use strict";

	angular.module("cardFrontConsumer.services")
	.factory("api", api);

	api.$inject = ["$q", "$log", "$http", "CARDFRONT_CONSUMER_SERVICE_FOLDER"];

	function api($q, $log, $http, CARDFRONT_CONSUMER_SERVICE_FOLDER) {

		return {
			getSite: getSite,
            lookupValues: {
                getCountry: getCountry,
				getCountries: getCountries,
				getRegions: getRegions,
                getFulfillmentTypes: getFulfillmentTypes,
                getCardUseTypes: getCardUseTypes,
                getIdentificationSourceTypes: getIdentificationSourceTypes
			},
			order: {
                calculateFees: calculateFees,
                calculateDiscount: calculateDiscount,
                submit: submitOrder,
                generateDisbursementAccount: generateDisbursementAccount,
                deleteDisbursementAccount: deleteDisbursementAccount,
                getInvoice: getInvoice
            },
            cards: {
                lookupByPrintText: lookupByPrintText,
                registerCard: registerCard,
                setPin: setPin,
                changePin: changePin
            }
		}

		function prefixUrl(url) {
			return CARDFRONT_CONSUMER_SERVICE_FOLDER + url;
		}

        function getInvoice(orderId, cultureName) {
			return $http.get(prefixUrl("/order/" + orderId + "/invoice?cultureName=" + encodeURIComponent(cultureName)), {
			    responseType: "arraybuffer"
			});
        }

		function getFulfillmentTypes() {
			return $http.get(prefixUrl("/fulfillmenttypes"));
		}

        function generateDisbursementAccount(corporateOrderBankAccountId, siteId) {
            var apiRoute = "/order/disbursementaccount/" + corporateOrderBankAccountId + "/site/" + siteId;
            return $http.post(prefixUrl(apiRoute));
        }

        function deleteDisbursementAccount(id) {
            var apiRoute = "/disbursement/" + id;
            return $http.delete(prefixUrl(apiRoute));
        }

		function submitOrder(order) {
			return $http.post(prefixUrl("/order/create"), order);
		}

		function calculateFees(siteId, cardAmounts) {

			return $http.post(prefixUrl("/order/calculatefees"), { siteId: siteId, orderItemCardAmounts: cardAmounts });
		}

		function calculateDiscount(siteId, siteVersionId, cardAmounts) {

			return $http.post(prefixUrl("/order/calculatediscount"), { siteId: siteId, siteVersionId: siteVersionId, cardAmountTotal: cardAmounts });
		}

		function getSite(pin) {
			return $http.post(prefixUrl("/site-public"), { pin: pin });
		}
		
		function getCountries(cultureName) {

            var url = "/countries";

            if (cultureName)
                url += "?cultureName=" + cultureName;

			return $http.get(prefixUrl(url));
        }

	    function getCountry(country) {
	        return $http.get(prefixUrl("/country/" + country));
	    }

		function getRegions(countryNumericCode, cultureName) {

            var url = "/countryregions/" + countryNumericCode;

            if (cultureName)
                url += "?cultureName=" + cultureName;

			return $http.get(prefixUrl(url));
        }

        function getCardUseTypes() {
            return $http.get(prefixUrl("/cardUseTypes"));
        }

        function getIdentificationSourceTypes() {
            return $http.get(prefixUrl("/identificationSourceTypes"));
        }

        function lookupByPrintText(printText, securityCode, programUniqueTag, cultureName) {            
            return $http.post(prefixUrl("/card/lookup"), { printText: printText, securityCode: securityCode, programUniqueTag: programUniqueTag, cultureName: cultureName });
        }

        function registerCard(card, customerModel) {
            if (card.isRegistered) {
                var updateModel =
				{
					printText: card.printText,
                    customerId: card.registeredCustomer.id,
                    isOrganization: false,
                    name: customerModel.firstName + " " + customerModel.lastName,
                    firstName: customerModel.firstName,
                    middleName: null,
					lastName: customerModel.lastName,
					addressId: customerModel.addressId,
                    emailAddress: customerModel.email,
                    phoneNumber: customerModel.phone,
                    phoneNumberExtension: null,
                    faxNumber: customerModel.fax,
                    faxNumberExtension: null,
                    address1: customerModel.address1,
                    address2: customerModel.address2,
                    city: customerModel.city,
                    country: customerModel.country.description,
                    region: customerModel.state.regionAbbreviation,
                    postalCode: customerModel.postalCode,
                    marketingOptIn: customerModel.marketingOptIn
                };
				return $http.put(prefixUrl("/card/register"),
                    updateModel);
            }

            var model =
			{
				printText: card.printText,
                customerId: 0,
				firstName: customerModel.firstName,
				middleName: null,
				lastName: customerModel.lastName,                
                email: customerModel.email,
                phone: customerModel.phone,
                phoneExt: null,
                fax: customerModel.fax,
                faxExt: null,
                address1: customerModel.address1,
                address2: customerModel.address2,
                city: customerModel.city,
                country: customerModel.country.description,
                stateOrProvince: customerModel.state.regionAbbreviation,
                postalCode: customerModel.postalCode,
                marketingOptIn: customerModel.marketingOptIn
            };            
			return $http.post(prefixUrl("/card/register"), model);
            
        }

        function setPin(printText, cardPin) {
			return $http.post(prefixUrl("/card/pin"), { printText: printText, pin: cardPin.Pin, confirmPin: cardPin.ConfirmPin });
        }

        function changePin(printText, cardPin) {
			return $http.patch(prefixUrl("/card/pin"), { printText: printText, oldPin: cardPin.OldPin, newPin: cardPin.Pin, confirmPin: cardPin.ConfirmPin });
        }   
        
    }
}());