(function () {
	"use strict";
	angular.module("angular.emlpayments")
        .factory('storeLinks', StoreLinks);

	StoreLinks.$inject = ["$location"];

	function StoreLinks($location) {
		var host = $location.host().toLowerCase(),
		prodRegex = /^.*cardfront.net$/,
		prodMatch = host.match(prodRegex),
		qaRegex = /(alpha|bravo|charlie|delta|foxtrot-0|foxtrot-1|foxtrot-2|foxtrot-3|foxtrot-4|foxtrot-5|foxtrot-6|foxtrot-7|foxtrot-8|foxtrot-9)/,
		qaMatch = host.match(qaRegex),
		testRegex = /(xtest|uat)/,
		testMatch = host.match(testRegex);

		var storeLinkUrl, infoCenterReportsUrl, forgotPasswordLinkUrl, cardspotMobileInstallUrl;

		if (prodMatch){
			storeLinkUrl =  "https://storelink.storefinancial.net/Default.aspx",
			infoCenterReportsUrl = "https://info.storefinancial.net/Default.aspx",
			cardspotMobileInstallUrl = infoCenterReportsUrl + "?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2";
		}
		else if (qaMatch) {
			storeLinkUrl = "https://storelink-" + qaMatch[0] + ".storefinancialdev.net/default.aspx",
			infoCenterReportsUrl = "https://info-" + qaMatch[0] + ".storefinancialdev.net/default.aspx",
			cardspotMobileInstallUrl = infoCenterReportsUrl + "?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2";
		} 
		else if (testMatch) {
			storeLinkUrl = "https://storelink." + testMatch[0] + ".storefinancial.net/Default.aspx",
			infoCenterReportsUrl = "https://info." + testMatch[0] + ".storefinancial.net/Default.aspx",
			cardspotMobileInstallUrl = infoCenterReportsUrl + "?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2"
		}
		else {
			storeLinkUrl = "http://local.storefinancialdev.net/storelink/Default.aspx",
			infoCenterReportsUrl = "http://local.storefinancialdev.net/cardpointreports/Default.aspx",
			cardspotMobileInstallUrl = "http://local.storefinancialdev.net/cardpointreports/Default.aspx?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2";
		}

		return {
			storeLinkUrl: storeLinkUrl,
			infoCenterReportsUrl: infoCenterReportsUrl,
			cardspotMobileInstallUrl: cardspotMobileInstallUrl
		};
	}
}());