(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
        .directive("sfHighValueTransactionInfo", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                orderItem: "=",
                site: "=",
				hvtInfo: "="				
            },
            controller: Controller,
            controllerAs: "model",
            templateUrl: "areas/sales/directives/highValueTransactionInfo.directive.html"
        };
    }

	Controller.$inject = ["$scope", "$rootScope", "navigate", "shoppingCart", "lookupValues"];

	function Controller($scope, $rootScope, navigate, shoppingCart, lookupValues) {
        var model = this;

        model.site = $scope.site;
        model.orderItem = $scope.orderItem;
        model.regions = [];
		model.countries = [];
		model.content = $rootScope.content;

        init();

        function init(callback) {
            var cultureCode = $rootScope.content.cultureCode;

            lookupValues.getCountries(cultureCode)
                .then(function (data) {
                    model.countries = data;
                    ensureCountry();
                    setRegionsForCountry();
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                });
            lookupValues.getCardUseTypes()
                .then(function (data) {
                    model.cardUseTypes = data;
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                });
            lookupValues.getIdentificationSourceTypes()
                .then(function (data) {
                    model.identificationSourceTypes = data;
                    if (angular.isFunction(callback)) {
                        callback();
                    }
				});	       
        }

        model.countrySelectionChanged = countrySelectionChanged;

        function countrySelectionChanged() {
            $scope.hvtInfo.postalCode = undefined;
            $scope.hvtInfo.state = undefined;
            $scope.hvtInfo.province = undefined;
            setRegionsForCountry();
        }

        function setRegionsForCountry() {
            if ($scope.hvtInfo && $scope.hvtInfo.country) {
                var cultureCode = $rootScope.content.cultureCode;

                lookupValues.getRegions($scope.hvtInfo.country.numericCode, cultureCode)
                    .then(function (regions) {
                        model.regions = _.sortBy(regions, function (region) { return region.description; });
                        model.regionsRestricted = _.reject(model.regions, function (region) { return region.isExcluded; });
                        model.showRegionSelection = $scope.hvtInfo.country && hasRegions();
                    });
            }
        }

        function ensureCountry() {
            if ($scope.hvtInfo && !$scope.hvtInfo.country && $scope.defaultCountryNumericCode) {
                var defaultCountry = _.find(model.countries, function (country) {
                    return hvtInfo.countryNumericCode === $scope.defaultCountryNumericCode;
                });
                if (defaultCountry) {
                    $scope.hvtInfo.country = angular.copy(defaultCountry);
                }
            }
        }

        function hasRegions() {
            return angular.isArray(model.regions) && model.regions.length > 0;
		}

		$scope.$watch("hvtInfo", function () {
			init();			
        });
        
        $rootScope.$watch("content.cultureCode", function(newValue, oldValue) {
			init();
		}, true);
    }

}());