(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
		.directive("sfCardAmounts", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				cardProduct: "=",
				currencySymbol: "=",
				customAmountEnabled: "=",
				minIssueValue: "=",
				maxIssueValue: "=",
				cardAmounts: "=",
				onContinue: "=",
				onCancel: "=",
                site: "=",
                isTrailingSymbol: "=",
                currencySeparators: "="
			},
			controller: CardAmountsController,
			controllerAs: "model",
			templateUrl: "areas/sales/directives/cardAmounts.directive.html"
		};
	}

	CardAmountsController.$inject = ["$scope", "shoppingCart", "EVENTS"];

	function CardAmountsController($scope, shoppingCart, EVENTS) {
		var model = this,
			cardAmountMaster = { quantity: 1, amount: 0 },
			cardAmountsMaster;

		model.minIssueValue = $scope.minIssueValue;
		model.maxIssueValue = $scope.maxIssueValue;
        model.cardAmounts = $scope.cardAmounts ? angular.copy($scope.cardAmounts) : [angular.copy(cardAmountMaster)];
		cardAmountsMaster = angular.copy(model.cardAmounts);
		model.addCard = addCard;
		model.removeCard = removeCard;
		model.customAmountEnabled = $scope.customAmountEnabled;
		model.saveAndContinue = saveAndContinue;
		model.setCardAmount = setCardAmount;
		model.cardAmountsValid = false; 
		model.cancel = cancel;
		model.amlLimit = $scope.site ? $scope.site.amlLimit : undefined;
		model.isBelowAmlLimit = true;
        model.cardAmountChanged = cardAmountChanged;
        model.selectedAmounts = [];
        model.selectedCardProduct = $scope.cardProduct;
        model.getTotal = getTotal;
		model.orderItem = shoppingCart.item();
        model.customAmountEnabled = $scope.customAmountEnabled;

		init();

		function init() {
			cardAmountChanged();
		}

		function cardAmountChanged() {			
			model.cardAmountsValid = cardAmountsValid();
		}

		function cancel() {
			model.cardAmounts = [angular.copy(cardAmountMaster)]; 
            model.selectedCardProduct = undefined;
			if (angular.isFunction($scope.onCancel)) {
				$scope.onCancel();
			}
		}

		function saveAndContinue() {			
			if (model.isBelowAmlLimit) {
				shoppingCart.setCardAmounts(angular.copy(model.cardAmounts));
				cardAmountsMaster = angular.copy(model.cardAmounts);

				getFees().then(function(fees) {
					$scope.$emit(EVENTS.CARD_FEES_CALCULATED, { fees: fees });
					if (angular.isFunction($scope.onContinue)) {
						$scope.onContinue();
					}
					if(model.orderItem.isCorporate && $scope.site.cardSalesDiscountEnabled) {
						getDiscount();
					}
				});
				
			}
		}

		function addCard() {
			model.cardAmounts.push(angular.copy(cardAmountMaster));
			cardAmountChanged();
		}

		function removeCard(cardAmount) {
			if (cardAmount) {
				model.cardAmounts = _.reject(model.cardAmounts, function(aCardAmount) {
					return cardAmount.$$hashKey === aCardAmount.$$hashKey;
					});
				cardAmountChanged();
			}
		}

		$scope.$watch("cardProduct", function (newCardProduct) {
            if (newCardProduct) {
                model.selectedCardProduct = newCardProduct;
				model.cardProductAmounts = $scope.cardProduct ? $scope.cardProduct.cardProductAmounts : [];
			}
		});

		function setCardAmount(cardAmount, amount) {
			cardAmount.amount = amount;
			cardAmountChanged();
		}

		function cardAmountsValid() {			
			return shoppingCart.cardAmountsValid(model.cardAmounts) && determineIsBelowAmlLimit();
		}

		function getFees() {
			return shoppingCart.calculateFees($scope.site.id, model.cardAmounts);
		}
		
		function getDiscount() {
			return shoppingCart.calculateDiscount($scope.site.id, $scope.site.siteVersionId, getTotal(model.cardAmounts));
		}

        function getTotal(items) {
            var total = 0;
            if (items && items.length > 0) {
                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    total += (item.quantity * item.amount);
                }
            }
            return total;
        };

		function determineIsBelowAmlLimit() {
			if (!model.amlLimit || model.orderItem.isCorporate) {
				return model.isBelowAmlLimit = true;				 
			}
			var total = getTotal(model.cardAmounts);
			return model.isBelowAmlLimit = total < model.amlLimit;
		}		
	}
}());
