(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.enterPin")
        .controller("EnterPin", EnterPin);

	EnterPin.$inject = ["$scope", "$translate", "navigate", "notify", "store", "siteService", "STORE_KEYS", "DEFAULT_LANG_KEY"];

	function EnterPin($scope, $translate, navigate, notify, store, siteService, STORE_KEYS, DEFAULT_LANG_KEY) {
		var model = this
		;
		model.submitPin = submitPin;
		model.pin = undefined;

		if (!$translate.use() && DEFAULT_LANG_KEY) {
			$translate.use(DEFAULT_LANG_KEY);
		}

		function submitPin() {
			notify.forFormSubmission($scope.pinForm, function() {
				store.set(STORE_KEYS.SITE_PIN, model.pin);
				var ignoreCache = true;
				siteService.get(ignoreCache).then(function(site) {
					navigate.toHome();
				},
				function () {
					notify.dismissErrorAlert();
					notify.showError("ENTER_PIN.VALIDATION_TEXT.PIN_NOT_VALID");
				});
				
			});
		}

	}
}());