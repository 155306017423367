(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
		.directive("sfShippingInfo", configDirective);

	function configDirective() {
		return {
			restrict: "E",
            scope: {
                orderItem: "=",
				shippingMethods: "=",
				onContinue: "=",
				continueButtonTranslationId: "@",
				currencySymbol: "=",
				onCancel: "=",
				defaultCountryNumericCode: "=",
				onSitePickupEnabled: "=",
				onSitePickupInstructions: "=",
				shippingRestrictionsEnabled: "=",
				countriesEnabled: "=?",
				shippingMethodRestrictionsEnabled: "=",
                shippingMethodRestrictionQuestion: "=",
                isTrailingSymbol: "=",
                currencySeparators: "="
			},
			controller: Controller,
			controllerAs: "model",
			templateUrl: "areas/sales/directives/shippingInfo.directive.html"
		};
	}

	Controller.$inject = ["$scope", "shoppingCart", "$translate", "lookupValues", "CONTACT_FIELDS", "EVENTS", "FULFILLMENT_TYPES", "SHIPPING_FULFILLMENT_NAME"];

	function Controller($scope, shoppingCart, $translate, lookupValues, CONTACT_FIELDS, EVENTS, FULFILLMENT_TYPES, SHIPPING_FULFILLMENT_NAME) {
		var model = this,
			shippingContactMaster,
			selectedShippingMethodIdMaster,
			selectedFulfillmentTypeIdMaster,
            shippingFulfillmentType;

        model.orderItem = $scope.orderItem;
		model.shippingMethods = _.sortBy($scope.shippingMethods, function (method) { return method.cost; });
		determineShippingMethodsByThreshold(model);
		model.unrestrictedShippingMethods = _.filter(model.shippingMethods, function(method) { return !method.isRestricted; });
		model.shippingContact = undefined;
		model.selectedShippingMethodId = model.orderItem && model.orderItem.selectedShippingMethod ? model.orderItem.selectedShippingMethod.id : undefined;
		model.selectedFulfillmentTypeId = model.orderItem && model.orderItem.selectedFulfillmentType ? model.orderItem.selectedFulfillmentType.id : undefined;
		model.contactTitleTranslationId = undefined;
		model.helpTextTranslationId = undefined;
		model.localizedShippingMethodName = localizedShippingMethodName;
		model.shippingMethodVisible = false;
		model.requiredFields = [
			CONTACT_FIELDS.FIRST_NAME,
			CONTACT_FIELDS.LAST_NAME,
			CONTACT_FIELDS.ADDRESS_ONE,
			CONTACT_FIELDS.CITY,
			CONTACT_FIELDS.STATE,
			CONTACT_FIELDS.POSTAL_CODE,
			CONTACT_FIELDS.COUNTRY
		];
		model.saveAndContinue = saveAndContinue;
		model.cancel = cancel;
		model.onSitePickupInstructions = $scope.onSitePickupInstructions;
		model.shippingMethodRestrictionsEnabled = $scope.shippingMethodRestrictionsEnabled;
		model.shippingMethodRestrictionQuestion = $scope.shippingMethodRestrictionQuestion;
		model.shippingMethodRestrictionQuestionAnswered = undefined;
		model.restrictShippingOptions = undefined;
		model.getShippingMethods = getShippingMethods;
		model.hasShippingMethods = hasShippingMethods;
		

		$scope.$watch("model.restrictShippingOptions", function () {
            if (model.restrictShippingOptions) {
                shoppingCart.setRestrictShippingOptions(model.restrictShippingOptions);
				model.shippingMethodRestrictionQuestionAnswered = true;
				if (model.restrictShippingOptions === "Yes") {
					var selectedShippingMethod = getSelectedShippingMethod();
					if (selectedShippingMethod && selectedShippingMethod.isRestricted) {
						model.selectedShippingMethodId = undefined;
					}
				}
			}
        });

        $scope.$watch("model.selectedShippingMethodId", function () {
            var translationConstant = getFulfillmentTypeConstant(model.selectedFulfillmentTypeId);

            if (translationConstant) {
                model.contactTitleTranslationId = translationConstant.TITLE_TRANSLATION_ID;
                model.helpTextTranslationId = translationConstant.HELP_TRANSLATION_ID;
                model.shippingMethodVisible = translationConstant.SHIPPING_METHOD_VISIBLE;
                model.selectedFulfillmentTypeIsShipping = model.selectedFulfillmentTypeId === FULFILLMENT_TYPES.SHIPPING.ID;
            }
            var selectedShippingMethod = getSelectedShippingMethod();

            shoppingCart.setSelectedShippingMethod(angular.copy(selectedShippingMethod));
            selectedShippingMethodIdMaster = model.selectedShippingMethodId;
            
            $scope.$emit(EVENTS.FULFILLMENT_INFO_CHANGED);
        });

		lookupValues.getFulfillmentTypes()
					.then(function (data) {
						model.fulfillmentTypes = data;
						_.each(model.fulfillmentTypes,
							function (fulfillmentType) {
								var translationConstant = getFulfillmentTypeConstant(fulfillmentType.id);
								if (translationConstant) {
									fulfillmentType.nameTranslationId = translationConstant.NAME_TRANSLATION_ID;
								}
							});
						initModel();
					});				

		function cancel() {
			model.shippingContact = angular.copy(shippingContactMaster);
			model.selectedShippingMethodId = selectedShippingMethodIdMaster;
			model.selectedFulfillmentTypeId = selectedFulfillmentTypeIdMaster;
			if (angular.isFunction($scope.onCancel)) {
				$scope.onCancel();
			}
		}

		function determineShippingMethodsByThreshold(model)
		{
			var thresholdMethods;
			var quantity = model.orderItem.cardAmounts.reduce(function (accumulator, currentValue) {
				return accumulator + currentValue.quantity;
			  }, 0);			
			// see if we have any thresholds
			thresholdMethods = _.filter(model.shippingMethods, function(method) 
			{ 
				return method.shippingQuantityThreshold > 0; 
			});
			if (thresholdMethods.length <  1)
			{
				return;
			}
			// see if we have any where the quantity is greater than the threshold
			// keeping track of the high threshold so can filter out the ranges
			var highThreshold=0;
			thresholdMethods = _.filter(model.shippingMethods, function(method) 
			{ 
				if (method.shippingQuantityThreshold > highThreshold && quantity > method.shippingQuantityThreshold)
				{
					highThreshold = method.shippingQuantityThreshold;
				}
				return quantity > method.shippingQuantityThreshold; 
			});
			if (thresholdMethods.length < 1)
			{
				return;
			}
			// found some, use them but first get rid of the ones lower than the high threshold
			thresholdMethods = removeLowThresholds(thresholdMethods, highThreshold);
			model.shippingMethods = thresholdMethods;

		}
		
		function removeLowThresholds(thresholdMethods, highThreshold)
		{
			if (highThreshold < 1)
			{
				return thresholdMethods;
			}
			var highhThresholdMethods = _.filter(thresholdMethods, function(method) 
			{ 
				return method.shippingQuantityThreshold >= highThreshold; 
			});
			return highhThresholdMethods;

		}

		function getShippingMethods() {
			return (model.shippingMethodRestrictionsEnabled && model.restrictShippingOptions === "Yes") ? model.unrestrictedShippingMethods : model.shippingMethods;
		}
		
		function hasShippingMethods() {
			return (getShippingMethods().length > 0);
		}

		function checkDefaultShippingMethod() {
			if(model.onSitePickupEnabled && !model.hasShippingMethods())
			{
				// if they only have the onsite pickup enabled, need to set the model.selectedFulfillmentTypeId to on site
				var fullfillmentType = _.find(model.fulfillmentTypes,
					function (fulfillmentType) {
						return fulfillmentType.nameTranslationId === "SHIPPING_INFO.FULFILLMENT_TYPES.ON_SITE_PICKUP";
					});
				if (!fullfillmentType)
				{
					return;
				}
				model.selectedFulfillmentTypeId = fullfillmentType.id;
			}
		}

		function saveAndContinue() {
			shoppingCart.setShippingContact(angular.copy(model.shippingContact));
			createShippingContactMaster(model.shippingContact);

			var selectedShippingMethod = getSelectedShippingMethod();

			shoppingCart.setSelectedShippingMethod(angular.copy(selectedShippingMethod));
			selectedShippingMethodIdMaster = model.selectedShippingMethodId;

			var selectedFulfillmentType = _.find(model.fulfillmentTypes, function (fulfillmentType) {
				return fulfillmentType.id === parseInt(model.selectedFulfillmentTypeId);
			});
			shoppingCart.setSelectedFulfillmentType(angular.copy(selectedFulfillmentType));
			selectedFulfillmentTypeIdMaster = model.selectedFulfillmentTypeId;

			shoppingCart.setRestrictShippingOptions(model.restrictShippingOptions);

			$scope.$emit(EVENTS.FULFILLMENT_INFO_CHANGED);

			if (angular.isFunction($scope.onContinue)) {
				$scope.onContinue();
			}
		}

		function getSelectedShippingMethod() {
			return _.find(model.shippingMethods, function (shippingMethod) {
				return shippingMethod.id === parseInt(model.selectedShippingMethodId);
			});
		}

		function localizedShippingMethodName(shippingMethod) {
			var option = _.find(shippingMethod.shippingMethodNames, function (name) {
				return name.cultureCode.toLowerCase() === $translate.currentLanguage().key.toLowerCase();
			});
			if (option) {
				return option.name;
			}
			return "";
		}

		function initModel() {
			var item = shoppingCart.item();

			model.restrictShippingOptions = item.restrictShippingOptions;

			model.shippingContact = angular.copy(item.shippingContact);
			createShippingContactMaster(model.shippingContact);

			var selectedShippingMethod = item.selectedShippingMethod;
			if (selectedShippingMethod) {
				model.selectedShippingMethodId = selectedShippingMethod.id;
				selectedShippingMethodIdMaster = selectedShippingMethod.id;
			}

			var selectedFulfillmentType = item.selectedFulfillmentType;
			if (selectedFulfillmentType) {
				model.selectedFulfillmentTypeId = selectedFulfillmentType.id;
				selectedFulfillmentTypeIdMaster = selectedFulfillmentType.id;
			}

			shippingFulfillmentType = _.find(model.fulfillmentTypes, function (fulfillmentType) {
				return fulfillmentType.name === SHIPPING_FULFILLMENT_NAME;
			});

			model.onSitePickupEnabled = $scope.onSitePickupEnabled;
			if (!model.onSitePickupEnabled && shippingFulfillmentType) {
				shoppingCart.setSelectedFulfillmentType(shippingFulfillmentType);
				model.selectedFulfillmentTypeId = shippingFulfillmentType.id;
			}

			setEnabledCountries();
			
			model.continueButtonTranslationId = $scope.continueButtonTranslationId || "GLOBAL.CONTINUE_TEXT";

			checkDefaultShippingMethod();

		}

		function setEnabledCountries() {
			var countriesEnabled = $scope.countriesEnabled &&
				(!model.onSitePickupEnabled || (model.selectedFulfillmentTypeId && model.selectedFulfillmentTypeIsShipping))
				? $scope.countriesEnabled : undefined;
			model.countriesEnabled = countriesEnabled;

		}

		function createShippingContactMaster(sourceShippingContact) {
			if (sourceShippingContact) {
				shippingContactMaster = angular.copy(sourceShippingContact);
				shippingContactMaster.country = angular.copy(sourceShippingContact.country);
			}
		}

		$scope.$watch("model.selectedFulfillmentTypeId", function () {
			var translationConstant = getFulfillmentTypeConstant(model.selectedFulfillmentTypeId);

			if (translationConstant) {
				model.contactTitleTranslationId = translationConstant.TITLE_TRANSLATION_ID;
				model.helpTextTranslationId = translationConstant.HELP_TRANSLATION_ID;
				model.shippingMethodVisible = translationConstant.SHIPPING_METHOD_VISIBLE;
				model.selectedFulfillmentTypeIsShipping = model.selectedFulfillmentTypeId === FULFILLMENT_TYPES.SHIPPING.ID;
			}
            setEnabledCountries();

            var selectedFulfillmentType = _.find(model.fulfillmentTypes, function (fulfillmentType) {
                return fulfillmentType.id === parseInt(model.selectedFulfillmentTypeId);
            });
            shoppingCart.setSelectedFulfillmentType(angular.copy(selectedFulfillmentType));
            selectedFulfillmentTypeIdMaster = model.selectedFulfillmentTypeId;
			
			if (!model.selectedFulfillmentTypeIsShipping) {
				model.selectedShippingMethodId = undefined;
				selectedShippingMethodIdMaster = model.selectedShippingMethodId;
			}
            
            $scope.$emit(EVENTS.FULFILLMENT_INFO_CHANGED);
		});

		function getFulfillmentTypeConstant(fulfillmentTypeId) {
			return _.find(FULFILLMENT_TYPES, function (FULFILLMENT_TYPE) {
				return FULFILLMENT_TYPE.ID === parseInt(fulfillmentTypeId);
			});

		}
	}
}());
