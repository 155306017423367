(function() {
    "use strict";
    angular.module("cardFrontConsumer")
        .config(configCheckBalanceHome);

    configCheckBalanceHome.$inject = ["$routeProvider", "NAV_URLS"];

    function configCheckBalanceHome($routeProvider, NAV_URLS) {
        $routeProvider
            .when(NAV_URLS.CHECK_BALANCE_HOME,
                {
                    templateUrl: "areas/check-balance/check-balance-home/check-balance-home.html",
                    controller: "CheckBalanceHome",
                    controllerAs: "model",
                    resolve: {
                        site: resolveSite
                    }
                })
            .otherwise({ redirectTo: NAV_URLS.HOME });
    }

    resolveSite.$inject = ["siteService"];

    function resolveSite(siteService) {
        return siteService.get();
    }
}());