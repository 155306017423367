(function () {
	"use strict";

	angular.module('angular.emlpayments')
        .directive('sfQrCode', qrCode);

	qrCode.$inject = [];

	function qrCode() {
		return function (scope, element, attrs) {

			new QRCode(element.get(0), {
				text: attrs.sfQrCode,
				width: attrs.height || 250,
				height: attrs.width || 250,
				colorDark: "#000000",
				colorLight: "#ffffff",
				correctLevel: QRCode.CorrectLevel.H
			});

		};
	}

}());