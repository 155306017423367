(function () {
    "use strict";

	angular.module("cardFrontConsumer")
        .config(configModule);

    configModule.$inject = ["$provide"];

    function configModule($provide) {
        $provide.decorator("navigate", decorateNavigateService);
    }

    decorateNavigateService.$inject = ["$delegate", "NAV_URLS"];

    function decorateNavigateService($delegate, NAV_URLS) {

        $delegate.toHome = function (referrer) {
        	$delegate.setReferrer(referrer);
        	$delegate.to(NAV_URLS.HOME);
        };

        $delegate.toSalesHome = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.SALES_HOME);
        };

        //$delegate.toShippingInfo = function (referrer) {
        //	$delegate.setReferrer(referrer);
        //	$delegate.to(NAV_URLS.SHIPPING_INFO);
        //};

        //$delegate.toPersonalize = function (referrer) {
        //	$delegate.setReferrer(referrer);
        //	$delegate.to(NAV_URLS.PERSONALIZE);
        //};

        $delegate.toReviewOrder = function (referrer) {
        	$delegate.setReferrer(referrer);
        	$delegate.to(NAV_URLS.REVIEW_ORDER);
        };

        $delegate.toShoppingCart = function (referrer) {
        	$delegate.setReferrer(referrer);
        	$delegate.to(NAV_URLS.SHOPPING_CART);
        };

        $delegate.toCheckout = function (referrer) {
        	$delegate.setReferrer(referrer);
        	$delegate.to(NAV_URLS.CHECKOUT);
        };

        $delegate.toOrderConfirmation = function (referrer) {
        	$delegate.setReferrer(referrer);
        	$delegate.to(NAV_URLS.ORDER_CONFIRMATION);
        };

        $delegate.toEnterPin = function (referrer) {
        	$delegate.setReferrer(referrer);
        	$delegate.to(NAV_URLS.ENTER_PIN);
        };

        $delegate.toCheckBalanceHome = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.CHECK_BALANCE_HOME);
        };

        $delegate.toOrderDetails = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.ORDER_DETAILS);
        }

        $delegate.toCorporateSalesHome = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.CORPORATE_SALES_HOME);
        }

        $delegate.setDefaultUrl(NAV_URLS.HOME);

        return $delegate;
    }

}());