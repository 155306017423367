(function () {
    "use strict";

    angular.module("cardFrontConsumer.services")
        .factory("siteService", configFactory);

    configFactory.$inject = ["$rootScope", "$translate", "api", "notify", "store", "STORE_KEYS", "navigate", "SITE_ELEMENT_TYPES", "DEFAULT_LANG_KEY", "NAV_URLS", "EML_LOGO_PATH"];

    function configFactory($rootScope, $translate, api, notify, store, STORE_KEYS, navigate, SITE_ELEMENT_TYPES, DEFAULT_LANG_KEY, NAV_URLS, EML_LOGO_PATH) {

        var getSitePromise;
        return {
            get: getSite
        };

        function getSite(ignoreCache) {
            if (!getSitePromise || ignoreCache) {
                var pin = store.get(STORE_KEYS.SITE_PIN);
                getSitePromise = notify.forHttp(api.getSite(pin), { startTranslationId: "PROGRESS_MESSAGES.GETTING_SITE" });
                getSitePromise.then(function (site) {
                    setLanguages(site);
                    setElementColors(site);
                    setSocialLinks(site);

                    var currentLanguage = $translate.currentLanguage().key;
                    if (!currentLanguage ||
                        (angular.isArray($rootScope.languages) &&
                            $rootScope.languages.length > 0 &&
                            !_.find($rootScope.languages, function (lang) {
                                return lang.cultureCode.toLowerCase() === currentLanguage.toLowerCase();
                            })
                        )
                    ) {
                        $rootScope.useLanguage($rootScope.languages[0].cultureCode);
                    } else {
                        $rootScope.useLanguage(currentLanguage);
                    }

                    $rootScope.consumerSalesEnabled = site.consumerSalesEnabled;
                    $rootScope.checkBalanceEnabled = site.checkBalanceEnabled;
                    $rootScope.termsOfUseLinkEnabled = site.termsOfUseLinkEnabled;

                    $rootScope.siteAppearanceLoaded = true;
                });
            }
            getSitePromise.then(null, function (error) {
                if (error.includes("403")) {
                    navigate.toEnterPin();
                }
            });
            return getSitePromise;
        }


        function setLanguages(site) {
            if (site) {
                $rootScope.languages = _.map(site.siteVersionCultures, function (siteVersionCulture) {
                    return {
                        cultureCode: siteVersionCulture.cultureCode,
                        cultureName: siteVersionCulture.cultureName,
                        translationId: "CULTURES." + siteVersionCulture.cultureCode.replace("-", "_")
                    };
                });
            }
        }

        function setElementColors(site) {
            if (site.fontOption && site.fontOption.fontFamily) {
                $rootScope.fontFamily = site.fontOption.fontFamily;
            }
            if (site.logoUrl) {
                $rootScope.logoUrl = site.logoUrl;
            }

            var defaultLogoNavigationUrl = '/#' + NAV_URLS.HOME;
            var customizedLogoNavigationUrl = site.logoNavigationUrl || defaultLogoNavigationUrl;
            $rootScope.logoNavigationUrl = site.customizeLogoNavigation ? customizedLogoNavigationUrl : defaultLogoNavigationUrl;

            if (angular.isArray(site.siteElementColors)) {
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.BODY_BACKGROUND, "bodyBackgroundColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.FONT, "fontColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.FOOTER_BACKGROUND, "footerBackgroundColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.FOOTER_FONT, "footerFontColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.HEADER_BACKGROUND, "headerBackgroundColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.HEADER_FONT, "headerFontColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.BUTTON, "buttonColor");
                $rootScope.buttonColorBorder = colorLuminance($rootScope.buttonColor, -0.05);
                $rootScope.buttonHoverColor = colorLuminance($rootScope.buttonColor, -0.50);
                $rootScope.buttonHoverBorderColor = colorLuminance($rootScope.buttonColor, -0.55);
                $rootScope.buttonHoverFontColor = colorLuminance($rootScope.buttonColor, -0.05);
                $rootScope.buttonFocusOutlineColor = colorLuminance($rootScope.buttonColor, 0.10);
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.HYPERLINK, "hyperlinkColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.ICON, "iconColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.BUTTON_B, "buttonBColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.BUTTON_C, "buttonCColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.NAV_BACKGROUND, "navBackgroundColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.NAV_FONT, "navFontColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.HOME_HERO_FONT, "homeHeroFontColor");
                setColor(site.siteElementColors, SITE_ELEMENT_TYPES.CORPORATE_HERO_FONT, "corporateHeroFontColor");
                $rootScope.buttonBFontColor = getForeColorBasedOnContrast($rootScope.buttonBColor);
                $rootScope.buttonBColorBorder = colorLuminance($rootScope.buttonBColor, -0.05);
                $rootScope.buttonBHoverColor = colorLuminance($rootScope.buttonBColor, -0.50);
                $rootScope.buttonBHoverBorderColor = colorLuminance($rootScope.buttonBColor, -0.55);
                $rootScope.buttonBHoverFontColor = colorLuminance($rootScope.buttonBFontColor, -0.05);
                $rootScope.buttonBFocusOutlineColor = colorLuminance($rootScope.buttonBColor, 0.10);
                $rootScope.buttonCColorBorder = colorLuminance($rootScope.buttonCColor, -0.05);
                $rootScope.buttonCHoverColor = colorLuminance($rootScope.buttonCColor, -0.50);
                $rootScope.buttonCHoverBorderColor = colorLuminance($rootScope.buttonCColor, -0.55);
                $rootScope.buttonCFocusOutlineColor = colorLuminance($rootScope.buttonCColor, 0.10);
                $rootScope.socialLinkButtonColor = getForeColorBasedOnContrast($rootScope.footerBackgroundColor);
                $rootScope.socialLinkButtonColorBorder = colorLuminance($rootScope.socialLinkButtonColor, -0.05);
                $rootScope.socialLinkButtonHoverColor = colorLuminance($rootScope.socialLinkButtonColor, -0.50);
                $rootScope.socialLinkButtonHoverBorderColor = colorLuminance($rootScope.socialLinkButtonColorBorder, -0.55);
                $rootScope.emlLogoPath = getEmlLogoPathBasedOnFooterColor($rootScope.footerBackgroundColor);
			}
		}

        function colorLuminance(hex, lum) {
            //ref: http://www.sitepoint.com/javascript-generate-lighter-darker-color/
            // validate hex string
            hex = String(hex).replace(/[^0-9a-f]/gi, '');
            if (hex.length < 6) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            lum = lum || 0;

            // convert to decimal and change luminosity
            var rgb = "#", c, i;
            for (i = 0; i < 3; i++) {
                c = parseInt(hex.substr(i * 2, 2), 16);
                c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                rgb += ("00" + c).substr(c.length);
            }

            return rgb;
        }

        function setColor(siteElementColors, elementType, propertyName) {
            var elementColor = _.find(siteElementColors, function (siteElementColor) {
                return siteElementColor.elementType === elementType;
            });
            if (elementColor) {
                $rootScope[propertyName] = elementColor.hexValue;
            }
        }

        function hexToRgb(hex) {
            //ref: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        }

        function getForeColorBasedOnContrast(hex) {
            var brightnessLevel = determineBrightness(hex);
            var fore = (brightnessLevel > 125) ? '#333333' : '#FFFFFF';

            return fore;
        }

        function determineBrightness(hex) {
            var rgb = hexToRgb(hex);

            //ref: http://www.w3.org/TR/AERT#color-contrast
            var brightnessLevel = Math.round(((parseInt(rgb.r) * 299) +
                (parseInt(rgb.g) * 587) +
                (parseInt(rgb.b) * 114)) / 1000);
            return brightnessLevel;
        }

        function getEmlLogoPathBasedOnFooterColor(hex) {
            var brightnessLevel = determineBrightness(hex);
            var emlLogoPath = (brightnessLevel > 125) ? EML_LOGO_PATH.BLACK : EML_LOGO_PATH.WHITE;

            return emlLogoPath;
        }

        function setSocialLinks(site) {
            $rootScope.socialLinks = site.socialLinks;
        }
    }
}());