(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(config);

	config.$inject = ["$routeProvider", "NAV_URLS"];

	function config($routeProvider, NAV_URLS) {

		$routeProvider
            .when(NAV_URLS.FAQS, {
            	templateUrl: "areas/faqs/faqs.html",
            	controller: "Faqs",
            	controllerAs: "model",
            	titleTranslationId: "FAQS.TITLE",
            	resolve: {
            		site: resolveSite
            	}
            });
	}

	resolveSite.$inject = ["siteService"];

	function resolveSite(siteService) {
		return siteService.get();
	}

}());