(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(config);

	config.$inject = ["$routeProvider", "NAV_URLS"];

	function config($routeProvider, NAV_URLS) {

		$routeProvider
            .when(NAV_URLS.REVIEW_ORDER, {
                templateUrl: "areas/sales/review-order/review-order.html",
            	controller: "ReviewOrder",
            	controllerAs: "model",
            	titleTranslationId: "REVIEW_ORDER.TITLE",
            	resolve: {
            		site: resolveSite
            	}
            });
	}
	resolveSite.$inject = ["siteService"];

	function resolveSite(siteService) {
		return siteService.get();
	}

}());