(function () {
    "use strict";

    angular.module("cardFrontConsumer")
        .config(config);

    config.$inject = ["$routeProvider", "NAV_URLS"];

    function config($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.ORDER_DETAILS, {
                templateUrl: "areas/sales/order-details/order-details.html",
                controller: "OrderDetails",
                controllerAs: "model",
                titleTranslationId: "ORDER_DETAILS.TITLE",
                resolve: {
                    site: resolveSite
                }
            });
    }
    resolveSite.$inject = ["siteService"];

    function resolveSite(siteService) {
        return siteService.get();
    }
}());