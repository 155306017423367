(function() {
    "use strict";

    angular.module("cardFrontConsumer")
        .config(configModule);

    configModule.$inject = ["$provide"];

    function configModule($provide) {
        $provide.decorator("cardService", decorateCardService);

        decorateCardService.$inject = ["$delegate", "api", "appConfig", "notify"];

        function decorateCardService($delegate, api, appConfig, notify) {
            

            $delegate.lookupByPrintText = function (printText, securityCode, programUniqueTag, cultureName, isModal) {

                return notify.forHttp(api.cards.lookupByPrintText(printText, securityCode, programUniqueTag, cultureName),
                    { startTranslationId: "PROGRESS_MESSAGES.GETTING_CARD", isModal: isModal });
            }

            return $delegate;
            
        }
    }
}());