(function() {
    "use strict";

    angular.module("cardFrontConsumer.areas.check-balance.check-balance-home")
        .controller("CheckBalanceHome", CheckBalanceHome);

    CheckBalanceHome.$inject = ["$scope", "site", "$rootScope"];

    function CheckBalanceHome($scope, site, $rootScope) {
        var model = this;
        model.site = site;        
        model.card = $scope.getCard();
        model.showCardNumber = false;
        model.toggleDisplayCarNumber = toggleDisplayCarNumber;
        model.currentDateFormatString = $scope.currentDateFormatString;
        model.currentDateMonthYearFormatString = $scope.currentDatemonthYearFormatString;
        model.orderByField = "activity";
        model.reverseSort = false;
        model.search = "";
        model.removeCard = removeCard;

        function toggleDisplayCarNumber() {
            model.showCardNumber = !model.showCardNumber;
        }

        function removeCard() {
            model.card = undefined;
            $rootScope.removeCard();
            $rootScope.clearCardLookup();
        }
    }
}());