(function () {
	"use strict";

	angular.module("cardFrontConsumer")
		.constant("NAV_URLS", {
			"HOME": "/",
			"FAQS": "/faqs",
			"CONTACT": "/contact",
			"ENTER_PIN": "/enter-pin",
			"TERMS_OF_USE": "/terms-of-use",
            "PRIVACY_POLICY": "/privacy-policy",
            "CARDHOLDER_AGREEMENT": "/cardholder-agreement",
            "LOCKED_CARD": "/locked-card",

            "REVIEW_ORDER": "/sales/review-order",
            "ORDER_DETAILS": "/sales/order-details",
            "ORDER_CONFIRMATION": "/sales/order-confirmation",
			"SALES_HOME": "/sales/sales-home",
			"CORPORATE_SALES_HOME": "/sales/sales-home?isCorporate=true",

            "CHECK_BALANCE_HOME": "/check-balance/check-balance-home",
            "REGISTER_CARD": "/check-balance/register-card",
            "CHANGE_PIN": "/check-balance/change-pin"
        })
        .constant("FOOTER_URLS", {
            "EML_TOU": "https://www.emlpayments.com/termsofuse"
        })
		.constant("CARDFRONT_CONSUMER_SERVICE_FOLDER", "/cardfront")
        .constant("DEFAULT_LANG_KEY", "en-US")
	    .constant("LANGUAGES", [
            { name: "CULTURES.EN_US", key: "en-us", dateFormat: "MDY", language: "en", privacyPolicyUrl: "https://www.emlpayments.com/privacy#EUUS", currencyFormat: { isTrailingSymbol: false, groupSeparator: "," , decimalSeparator: "." } },
            { name: "CULTURES.EN_CA", key: "en-ca", dateFormat: "MDY", language: "en", privacyPolicyUrl: "https://www.emlpayments.com/privacy#EUUS", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: "." } },
            { name: "CULTURES.EN_GB", key: "en-gb", dateFormat: "DMY", language: "en", privacyPolicyUrl: "https://www.emlpayments.com/privacy#EUEN", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: "." } },
            { name: "CULTURES.FR_CA", key: "fr-ca", dateFormat: "YMD", language: "fr", privacyPolicyUrl: "https://www.emlpayments.com/privacy#french2", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.FR_FR", key: "fr-fr", dateFormat: "YMD", language: "fr", privacyPolicyUrl: "https://www.emlpayments.com/privacy#french1", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.DE_DE", key: "de-de", dateFormat: "YMD", language: "de", privacyPolicyUrl: "https://www.emlpayments.com/privacy#GERMAN", currencyFormat: { isTrailingSymbol: true, groupSeparator: ".", decimalSeparator: "," } },
            { name: "CULTURES.DE_AT", key: "de-at", dateFormat: "YMD", language: "de", privacyPolicyUrl: "https://www.emlpayments.com/privacy#GERMAN", currencyFormat: { isTrailingSymbol: true, groupSeparator: ".", decimalSeparator: "," } },
            { name: "CULTURES.IT_IT", key: "it-it", dateFormat: "DMY", language: "it", privacyPolicyUrl: "https://www.emlpayments.com/privacy#italian1", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.ES_ES", key: "es-es", dateFormat: "DMY", language: "es", privacyPolicyUrl: "https://www.emlpayments.com/privacy#spanish1", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.NL_NL", key: "nl-nl", dateFormat: "DMY", language: "nl", privacyPolicyUrl: "https://www.emlpayments.com/privacy#dutch1", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.NL_BE", key: "nl-be", dateFormat: "DMY", language: "nl", privacyPolicyUrl: "https://www.emlpayments.com/privacy#flemish1", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.ES_MX", key: "es-mx", dateFormat: "DMY", language: "es", privacyPolicyUrl: "https://www.emlpayments.com/privacy#spanish1", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.FR_BE", key: "fr-be", dateFormat: "YMD", language: "fr", privacyPolicyUrl: "https://www.emlpayments.com/privacy#french1", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: "," } },
            { name: "CULTURES.PT_PT", key: "pt-pt", dateFormat: "DMY", language: "pt", privacyPolicyUrl: "https://www.emlpayments.com/privacy#portuguese1", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: "," } }
	    ])
		.constant("DATE_FORMATS", [
			{ name: "YMD", formatString: "yyyy-MM-dd", monthYearFormatString: "MM-yyyy" },
            { name: "MDY", formatString: "MM/dd/yyyy", monthYearFormatString: "MM/yyyy" },
            { name: "DMY", formatString: "dd-MM-yyyy", monthYearFormatString: "MM-yyyy" }
		])
		.constant("SITE_ELEMENT_TYPES", {
			"BODY_BACKGROUND": "Body Background",
			"BUTTON": "Button",
			"ICON": "Icon",
			"FONT": "Font",
			"FOOTER_BACKGROUND": "Footer Background",
			"FOOTER_FONT": "Footer Font",
			"HEADER_BACKGROUND": "Header Background",
			"HEADER_FONT": "Header Font",
            "HYPERLINK": "Hyperlink",
            "BUTTON_B": "Button B",
            "BUTTON_C": "Button C",
            "NAV_BACKGROUND": "Nav Background",
            "NAV_FONT": "Nav Font",
            "HOME_HERO_FONT": "Home Hero Font",
            "CORPORATE_HERO_FONT": "Corporate Hero Font"
        })
        .constant("SITE_COLORS", {
            "PRIMARY": "Primary",
            "SECONDARY": "Secondary",
            "ACCENT": "Accent",
            "NEUTRAL": "Neutral"
        })
		.constant("DEFAULT_DATE_FORMAT", "MDY")
		.constant("STORE_KEYS", {
			"SHOPPING_CART": "sf-shopping-cart",
			"ORDER_ITEM": "sf-order-item",
			"SUBMITTED_ORDER": "sf-submitted-order",
            "SITE_PIN": "sf-site-pin",
			"CARD": "sf-card",
			"SECURITY_CODE": "sf-security-code"
		})
		.constant("CONTACT_FIELDS", {
			"FIRST_NAME": "firstName",
			"LAST_NAME": "lastName",
			"ADDRESS_ONE": "addressOne",
			"CITY": "city",
			"STATE": "state",
			"POSTAL_CODE": "postalCode",
			"COUNTRY": "country",
			"EMAIL": "email",
            "PHONE": "phone",
            "BUSINESS_NAME": "businessName",
			"COMMERCIAL_REGISTRATION_NUMBER": "companyRegistrationNumber",
			"PEP_ANSWER": "pepAnswer"
		})
		.constant("EVENTS", {
			"CARD_FEES_CALCULATED": "sf-card-fees-calculated",
            "FULFILLMENT_INFO_CHANGED": "sf-fulfillment-info-changed"
		})
		.constant("FULFILLMENT_TYPES", {
			"ON_SITE_PICKUP": {
				"ID": 2,
				"NAME_TRANSLATION_ID": "SHIPPING_INFO.FULFILLMENT_TYPES.ON_SITE_PICKUP",
				"TITLE_TRANSLATION_ID": "SHIPPING_INFO.LABELS.PICKUP_CONTACT",
				"HELP_TRANSLATION_ID": "SHIPPING_INFO.LABELS.PICKUP_CONTACT_HELP_TEXT",
				"SHIPPING_METHOD_VISIBLE": false
			},
			"SHIPPING": {
				"ID": 1,
				"NAME_TRANSLATION_ID": "SHIPPING_INFO.FULFILLMENT_TYPES.SHIPPING",
				"TITLE_TRANSLATION_ID": "SHIPPING_INFO.LABELS.SHIPPING_CONTACT",
				"HELP_TRANSLATION_ID": "SHIPPING_INFO.LABELS.SHIPPING_CONTACT_HELP_TEXT",
				"SHIPPING_METHOD_VISIBLE": true
			}
		})
		.constant("SHIPPING_FULFILLMENT_NAME", "Shipping")
		.constant("PAGE_TITLE_INTERPOLATION_EXPRESSION", "{{pageTitle}}")
        .constant("EML_LOGO_PATH", {
            "BLACK": "content/images/eml_logo_black.png",
            "WHITE": "content/images/eml_logo_white.png"
        })
        .constant("HVT_CORPORATE_THRESHOLD_AMOUNT", 50000)
	;
}());