//This is just a shell for injecting into the card decorator service
(function () {
    "use strict";

    angular.module("cardFrontConsumer.services")
        .factory("appConfig", configFactory);

    configFactory.$inject = [];

    function configFactory() {
        return {};
    }
}());
