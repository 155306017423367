(function() {
    "use strict";

    angular.module("cardFrontConsumer.services")
        .factory("registerCardService", configFactory);

    configFactory.$inject = ["$rootScope", "api", "notify"];

    function configFactory($rootScope, api, notify) {
        return {
            register: register
        };

        function register(card, customerModel) {
            return notify.forHttp(api.cards.registerCard(card, customerModel), { startTranslationId: "PROGRESS_MESSAGES.REGISTERING_CARD" });
        }
    }
}());