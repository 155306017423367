(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(config);

	config.$inject = ["$routeProvider", "NAV_URLS"];

	function config($routeProvider, NAV_URLS) {

		$routeProvider
            .when(NAV_URLS.PRIVACY_POLICY, {
            	templateUrl: "areas/privacy-policy/privacy-policy.html",
            	controller: "PrivacyPolicy",
            	controllerAs: "model",
            	titleTranslationId: "PRIVACY_POLICY.TITLE",
            	resolve: {
            		site: resolveSite
            	}
            });

		resolveSite.$inject = ["siteService"];

		function resolveSite(siteService) {
			return siteService.get();
		}
	}
}());