(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
		.directive("sfPersonalize", configDirective);

	function configDirective() {
		return {
			restrict: "E",
            scope: {
                personalization: "=",
				onContinue: "=",
				showSkipButton: "=",
				continueButtonTranslationId: "@",
				onCancel: "="
		},
			controller: Controller,
			controllerAs: "model",
			templateUrl: "areas/sales/directives/personalize.directive.html"
		};
	}

	Controller.$inject = ["$scope", "shoppingCart"];

	function Controller($scope, shoppingCart) {
		var model = this, personalizationMaster
		;
		model.saveAndContinue = saveAndContinue;
		model.skip = skip;
        personalizationMaster = angular.copy($scope.personalization);
		model.showSkipButton = $scope.showSkipButton;
		model.continueButtonTranslationId = $scope.continueButtonTranslationId || "GLOBAL.CONTINUE_TEXT";
		model.cancel = cancel;
		model.messageMaxLength = 75;
		model.remainingMessageCharacters = remainingMessageCharacters;

		function remainingMessageCharacters() {
			if(!$scope.personalization || !$scope.personalization.message)
				return 0;

			return model.messageMaxLength - $scope.personalization.message.length;
		}

		function cancel() {
			model.personalization = angular.copy(personalizationMaster);
			if (angular.isFunction($scope.onCancel)) {
				$scope.onCancel();
			}
		}

		function saveAndContinue() {
			doContinue();
		}

		function skip() {
			model.personalization = {};
			personalizationMaster = {};
			shoppingCart.setPersonalizationInfo({});

			doContinue();
		}

		function doContinue() {
			if (angular.isFunction($scope.onContinue)) {
				$scope.onContinue();
			}
		}

	}
}());
