(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.contact")
        .controller("Contact", Contact);

	Contact.$inject = ["site"];

	function Contact(site) {
		var model = this
		;

		model.site = site;
	}
}());