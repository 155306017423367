(function () {
    "use strict";
    angular.module("angular.emlpayments")
        .factory('apiUrlFormatter', ApiUrlFormatter);

    ApiUrlFormatter.$inject = ["$location"];

    function ApiUrlFormatter($location) {

        var serviceHost;

        return {
            format: format
        };

        function determineHost() {
            var host = $location.host().toLowerCase(),
                prodRegex = /^.*cardfront.net$/,
                prodMatch = host.match(prodRegex),
                qaRegex = /(alpha|bravo|charlie|delta|foxtrot-[0-9]{1,2})/,
                qaMatch = host.match(qaRegex),
                testRegex = /(xtest|uat)/,
                testMatch = host.match(testRegex);
        
            if (prodMatch) {
                //prod
                serviceHost = "webservices.storefinancial.net";
            } 
            else if (qaMatch) {
                //we are in one of the four QA envirnoments, concatenate appropriate host
                serviceHost = "webservices-" + qaMatch[0] + ".storefinancialdev.net";
	        } 
	        else if (testMatch) { 
                //we are in xtest test environment
                serviceHost = "webservices." + testMatch[0] + ".storefinancial.net";
            }
            else  {
				// local 
				serviceHost = "local.storefinancialdev.net";
			}

            if (!serviceHost) {
                throw "The debug API URL Formatter could not determine the web services URL host.";
            }
        }

        function format(url) {
            if (!serviceHost) {
                determineHost();
            }
            return "https://" + serviceHost + url;
        }
    }

}());