(function () {
    "use strict";

	angular.module("cardFrontConsumer")
        .config(configTranslate);

    configTranslate.$inject = ["$translateProvider", "DEFAULT_LANG_KEY"];
    function configTranslate($translateProvider, DEFAULT_LANG_KEY) {
        $translateProvider.useSanitizeValueStrategy("escape");
        $translateProvider.useStaticFilesLoader({
            prefix: "/content/locales/",
            suffix: ".json"
        });
        $translateProvider.fallbackLanguage(DEFAULT_LANG_KEY);
        $translateProvider.useLocalStorage();
    }

}());