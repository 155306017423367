(function () {
    "use strict";
    angular.module("cardFrontConsumer")
        .config(configRegisterCard);

    configRegisterCard.$inject = ["$routeProvider", "NAV_URLS"];

    function configRegisterCard($routeProvider, NAV_URLS) {
        $routeProvider
            .when(NAV_URLS.REGISTER_CARD,
                {
                    templateUrl: "areas/check-balance/register-card/register-card.html",
                    controller: "RegisterCard",
                    controllerAs: "model",
                    resolve: {
                        site: resolveSite
                    }
                })
            .otherwise({ redirectTo: NAV_URLS.HOME });
    }

    resolveSite.$inject = ["siteService"];

    function resolveSite(siteService) {
        return siteService.get();
    }
}());