(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.check-balance.register-card")
        .controller("ChangePin", ChangePin);

    ChangePin.$inject = ["$scope", "site", "$rootScope", "cardPinService", "navigate"];

    function ChangePin($scope, site, $rootScope, cardPinService, navigate) {
        var model = this;
        model.site = site;
        model.card = $scope.getCard();
        model.content = $rootScope.content;
        model.save = save;        
        model.navigatePrevious = navigatePrevious; 
        model.modalShown = false;
        model.showModal = showModal;   

    function save(){
        if(model.card.canSetPin){
            setPin();
        }
        if(model.card.canChangePin){
            changePin();
        }
    }

    function setPin(){
        cardPinService.setPin(model.card.printText, angular.copy(model.cardPin)).then(function(data){
            if(data.succeeded){
                var card = angular.copy(model.card);
                card.canChangePin = true;
                card.canSetPin = false;
                $scope.setCard(card);
                showModal();
            }
        });
    }

    function changePin(){
        cardPinService.changePin(model.card.printText, angular.copy(model.cardPin)).then(function(data){
            if(data.succeeded){
                showModal();
            }
        });
    }

    function navigatePrevious() {
        navigate.toCheckBalanceHome();
    } 
    
    function showModal() {
        model.modalShown = !model.modalShown;
    }    
}  
}());