(function () {
	"use strict";

	angular.module("cardFrontConsumer.services")
		.factory("shoppingCart", configFactory);

    configFactory.$inject = ["$q", "api", "notify", "store", "STORE_KEYS", "SHIPPING_FULFILLMENT_NAME", "HVT_CORPORATE_THRESHOLD_AMOUNT"];

    function configFactory($q, api, notify, store, STORE_KEYS, SHIPPING_FULFILLMENT_NAME, HVT_CORPORATE_THRESHOLD_AMOUNT) {

        var cardAmountMaster = { quantity: 1, amount: 0 };

		return {
			item: getItem,
			hasItem: hasItem,
			getCardAmounts: getCardAmounts,
			setCardAmounts: setCardAmounts,
			haveCardAmounts: haveCardAmounts,
			addCardAmount: addCardAmount,
			removeCardAmount: removeCardAmount,
			cardAmountsValid: cardAmountsValid,
			setCardProduct: setCardProduct,
			getCardProduct: getCardProduct,
			cardProductsAndAmountsValid: cardProductsAndAmountsValid,
			setShippingContact: setShippingContact,
			getShippingContact: getShippingContact,
			setSelectedShippingMethod: setSelectedShippingMethod,
			getSelectedShippingMethod: getSelectedShippingMethod,
			setSelectedFulfillmentType: setSelectedFulfillmentType,
			getSelectedFulfillmentType: getSelectedFulfillmentType,
			getRestrictShippingOptions: getRestrictShippingOptions,
			setRestrictShippingOptions: setRestrictShippingOptions,
			shippingInfoValid: shippingInfoValid,
			getPersonalizationInfo: getPersonalizationInfo,
			setPersonalizationInfo:setPersonalizationInfo,
			getBillingContact: getBillingContact,
			setBillingContact : setBillingContact,
			getPaymentInformation: getPaymentInformation,
			setPaymentInformation: setPaymentInformation,
			paymentAndBillingInfoValid: paymentAndBillingInfoValid,
			calculateFees: calculateFees,
			calculateDiscount: calculateDiscount,
			getFees: getFees,
			getDiscount: getDiscount,
			getOptIn: getOptIn,
			setOptIn: setOptIn,
			getTermsAgreed: getTermsAgreed,
			setTermsAgreed: setTermsAgreed,
			reset: reset,
			ensure: {
				shippingContact: ensureShippingContact,
				billingContact: ensureBillingContact,
				paymentInformation: ensurePaymentInformation,
				personalization: ensurePersonalization

			},
			submitOrder: submitOrder,
			getSubmittedOrder: getSubmittedOrder,
			setSubmittedOrder: setSubmittedOrder,
            getOrderTotals: getOrderTotals,
            setSalesType: setSalesType,
            setHighValueTransactionInfo: setHighValueTransactionInfo,
            getHighValueTransactionInfo: getHighValueTransactionInfo,
            setDisbursementAccountId: setDisbursementAccountId,
			getDisbursementAccountId: getDisbursementAccountId,
			setDisbursementReferenceNumber: setDisbursementReferenceNumber,
			getDisbursementReferenceNumber: getDisbursementReferenceNumber,
			setCustomerOrderReference: setCustomerOrderReference,
			getCustomerOrderReference: getCustomerOrderReference,
			setBankAccountName: setBankAccountName,
			getBankAccountName: getBankAccountName,
			setAccountIdentifier: setAccountIdentifier,
			getAccountIdentifier: getAccountIdentifier,
			setRoutingNumber: setRoutingNumber,
			getRoutingNumber: getRoutingNumber,
			setAccountNumber: setAccountNumber,
			getAccountNumber: getAccountNumber,
			setIban: setIban,
			getIban: getIban,
			setBic: setBic,
			getBic: getBic,
			setPurchaserInfo: setPurchaserInfo
		};

		function getTermsAgreed() {
			return getItem().termsAgreed;
		}

		function setTermsAgreed(termsAgreed) {
			var item = getItem();
			item.termsAgreed = termsAgreed;
			saveItem(item);
		}

		function getSubmittedOrder() {
			return store.get(STORE_KEYS.SUBMITTED_ORDER);
		}

		function setSubmittedOrder(order) {
			store.set(STORE_KEYS.SUBMITTED_ORDER, order);

		}

		function submitOrder(site, currentLanguage, captchaResponse) {
            var item = angular.copy(getItem());
			item.captchaResponse = captchaResponse;
            var mapFunction;
            if (!item.isCorporate) {
                mapFunction = mapConsumerOrderModel;
            } else {
                mapFunction = mapCorporateOrderModel;
            }
			return notify.forHttp(api.order.submit(mapFunction(item, site, currentLanguage)), { startTranslationId: "PROGRESS_MESSAGES.SUBMITTING_ORDER" })
				.then(function (response) {
                    var order = angular.copy(getItem());
                    if (order.paymentInformation) {
                        order.paymentInformation.creditCardNumber = order.paymentInformation.creditCardNumber.replace(/\d(?=\d{4})/g, "*");
                    }
					order.orderId = response.message;
					setSubmittedOrder(order);
					reset();
				});
		}

		function mapConsumerOrderModel(item, site, currentLanguage) {
            var totals = getOrderTotals(item);
            var siteVersionCulture = _.find(site.siteVersionCultures, function (siteVersionCulture) {
                return siteVersionCulture.cultureCode.toLowerCase() === currentLanguage.toLowerCase();
            });

			return {
				"siteId": site.id,
				"siteVersionId":site.siteVersionId,
				"customerOrderReference": null,
                "isCorporate": item.isCorporate,
                "disbursementAccountId": null,
				"disbursementReferenceNumber": null,
				"bankAccountName": null,
				"accountIdentifier": null,
				"bankAccountNumber": null,
				"bankRoutingNumber": null,
				"iban": null,
				"bic": null,
				"defaultCulture": currentLanguage,
				"paymentInformation": {
					"cardNumber": item.paymentInformation.creditCardNumber,
					"nameOnCard": item.paymentInformation.nameOnCard,
					"expirationMonth": item.paymentInformation.expiryMonth,
					"expirationYear": item.paymentInformation.expiryYear,
					"cvv2": item.paymentInformation.cvv
				},
				"billingInformation": {
					"firstName": item.billingContact.firstName,
					"lastName": item.billingContact.lastName,
					"address1": item.billingContact.address1,
                    "address2": item.billingContact.address2,
                    "address3": item.billingContact.address3,
                    "address4": item.billingContact.address4,
					"city": item.billingContact.city,
					"stateOrProvince": item.billingContact.state ? item.billingContact.state.regionAbbreviation : null,
					"zipOrPostalCode": item.billingContact.postalCode,
					"country": item.billingContact.country.description,
					"emailAddress": item.billingContact.email,
					"phone": item.billingContact.phone,
					"receiveCommunications": item.optIn,
					"companyRegistrationNumber": item.billingContact.companyRegistrationNumber,
					"vat":item.billingContact.vat,
					"commercialRegistrationNumber": item.billingContact.commercialRegistrationNumber,
					"isPEP": item.billingContact.isPEP
				},
				"amountTotal": totals.cardAmount,
				"feeTotal": totals.fees,
				"discountTotal": totals.discount,
				"shippingCostTotal": totals.shipping,
				"orderTotal": totals.orderTotal,
				"orderItems":
				[
                    {
                        "cardProduct": item.selectedCardProduct,
						"cardProductId": item.selectedCardProduct.id,
						"shippingInformation": {
							"shippingMethodId": item.selectedShippingMethod ? item.selectedShippingMethod.id : null,
							"firstName": item.shippingContact.firstName,
							"lastName": item.shippingContact.lastName,
							"address1": item.shippingContact.address1,
                            "address2": item.shippingContact.address2,
                            "address3": item.shippingContact.address3,
                            "address4": item.shippingContact.address4,
							"city": item.shippingContact.city,
							"stateOrProvince": item.shippingContact.state ? item.shippingContact.state.regionAbbreviation : null,
							"zipOrPostalCode": item.shippingContact.postalCode,
							"country": item.shippingContact.country.description,
							"phone": item.shippingContact.phone
						},
						"to": item.personalization ? item.personalization.to : null,
						"from": item.personalization ? item.personalization.from : null,
						"message": item.personalization ? item.personalization.message : null,
						"orderItemCardAmounts": item.cardAmounts,
						"fulfillmentTypeId": item.selectedFulfillmentType.id
					}
                ],
                "hvtInfo": null,
                "supportPhoneNumber": siteVersionCulture.supportPhoneNumber,
				"captchaResponse": item.captchaResponse,
				"supportEmailAddress": siteVersionCulture.supportEmailAddress
			};
        }

        function mapCorporateOrderModel(item, site, currentLanguage) {
			var totals = getOrderTotals(item);
            var siteVersionCulture = _.find(site.siteVersionCultures, function (siteVersionCulture) {
                return siteVersionCulture.cultureCode.toLowerCase() === currentLanguage.toLowerCase();
			});

            if (totals.orderTotal < HVT_CORPORATE_THRESHOLD_AMOUNT) {
                return {
					"siteId": site.id,
					"siteVersionId":site.siteVersionId,
					"isCorporate": item.isCorporate,
					"customerOrderReference": item.customerOrderReference,
                    "disbursementAccountId": item.disbursementAccountId,
					"disbursementReferenceNumber": item.disbursementReferenceNumber,
					"bankAccountName": item.bankAccountName,
					"accountIdentifier": item.accountIdentifier,
					"bankAccountNumber": item.accountNumber,
					"bankRoutingNumber": item.routingNumber,
					"iban": item.iban,
					"bic": item.bic,
                    "defaultCulture": currentLanguage,
                    "paymentInformation": null,
                    "billingInformation": {
                        "firstName": item.shippingContact.firstName,
                        "lastName": item.shippingContact.lastName,
                        "address1": item.shippingContact.address1,
                        "address2": item.shippingContact.address2,
                        "address3": item.shippingContact.address3,
                        "address4": item.shippingContact.address4,
                        "city": item.shippingContact.city,
                        "stateOrProvince": item.shippingContact.state ? item.shippingContact.state.regionAbbreviation : null,
                        "zipOrPostalCode": item.shippingContact.postalCode,
                        "country": item.shippingContact.country.description,
                        "emailAddress": item.shippingContact.email,
                        "phone": item.shippingContact.phone,
                        "receiveCommunications": item.optIn,
                        "companyName": item.shippingContact.companyName,
						"companyRegistrationNumber": item.billingContact.companyRegistrationNumber,
						"vat":item.billingContact.vat,
						"commercialRegistrationNumber": item.billingContact.commercialRegistrationNumber,
						"isPEP": item.billingContact.isPEP
                    },
                    "amountTotal": totals.cardAmount,
					"feeTotal": totals.fees,
					"discountTotal": totals.discount,
					"shippingCostTotal": totals.shipping,
                    "orderTotal": totals.orderTotal,
                    "orderItems":
                    [
                        {
                            "cardProduct": item.selectedCardProduct,
                            "cardProductId": item.selectedCardProduct.id,
                            "shippingInformation": {
                                "shippingMethodId": item.selectedShippingMethod ? item.selectedShippingMethod.id : null,
                                "firstName": item.shippingContact.firstName,
                                "lastName": item.shippingContact.lastName,
                                "address1": item.shippingContact.address1,
                                "address2": item.shippingContact.address2,
                                "address3": item.shippingContact.address3,
                                "address4": item.shippingContact.address4,
                                "city": item.shippingContact.city,
                                "stateOrProvince": item.shippingContact.state ? item.shippingContact.state.regionAbbreviation : null,
                                "zipOrPostalCode": item.shippingContact.postalCode,
                                "country": item.shippingContact.country.description,
                                "companyName": item.shippingContact.companyName
                            },
                            "to": item.personalization ? item.personalization.to : null,
                            "from": item.personalization ? item.personalization.from : null,
                            "message": item.personalization ? item.personalization.message : null,
                            "orderItemCardAmounts": item.cardAmounts,
                            "fulfillmentTypeId": item.selectedFulfillmentType.id
                        }
                    ],
                    "hvtInfo": null,
                    "supportPhoneNumber": siteVersionCulture.supportPhoneNumber,
					"supportEmailAddress": siteVersionCulture.supportEmailAddress
                };
            } else {
                return {
					"siteId": site.id,
					"isCorporate": item.isCorporate,
					"customerOrderReference": item.customerOrderReference,
                    "disbursementAccountId": item.disbursementAccountId,
					"disbursementReferenceNumber": item.disbursementReferenceNumber,
					"bankAccountName": item.bankAccountName,
					"accountIdentifier": item.accountIdentifier,
					"bankAccountNumber": item.accountNumber,
					"bankRoutingNumber": item.routingNumber,
					"iban": item.iban,
					"bic": item.bic,
                    "defaultCulture": currentLanguage,
                    "paymentInformation": null,
                    "billingInformation": {
                        "firstName": item.hvtInfo.firstName,
                        "lastName": item.hvtInfo.lastName,
                        "address1": item.hvtInfo.address1,
                        "address2": item.hvtInfo.address2,
                        "address3": item.hvtInfo.address3,
                        "address4": item.hvtInfo.address4,
                        "city": item.hvtInfo.city,
                        "stateOrProvince": item.hvtInfo.state.regionAbbreviation,
                        "zipOrPostalCode": item.hvtInfo.postalCode,
                        "country": item.hvtInfo.country.description,
                        "emailAddress": item.hvtInfo.email,
                        "phone": null,
                        "receiveCommunications": item.optIn,
                        "companyName": item.hvtInfo.companyName,
						"companyRegistrationNumber": item.billingContact.companyRegistrationNumber,
						"vat":item.billingContact.vat,
						"commercialRegistrationNumber": item.billingContact.commercialRegistrationNumber,
						"isPEP": item.billingContact.isPEP
                    },
                    "amountTotal": totals.cardAmount,
					"feeTotal": totals.fees,
					"discountTotal": totals.discount,
					"shippingCostTotal": totals.shipping,
                    "orderTotal": totals.orderTotal,
                    "orderItems":
                    [
                        {
                            "cardProduct": item.selectedCardProduct,
                            "cardProductId": item.selectedCardProduct.id,
                            "shippingInformation": {
                                "shippingMethodId": item.selectedShippingMethod ? item.selectedShippingMethod.id : null,
                                "firstName": item.shippingContact.firstName,
                                "lastName": item.shippingContact.lastName,
                                "address1": item.shippingContact.address1,
                                "address2": item.shippingContact.address2,
                                "address3": item.shippingContact.address3,
                                "address4": item.shippingContact.address4,
                                "city": item.shippingContact.city,
                                "stateOrProvince": item.shippingContact.state ? item.shippingContact.state.regionAbbreviation : null,
                                "zipOrPostalCode": item.shippingContact.postalCode,
                                "country": item.shippingContact.country.description,
                                "companyName": item.shippingContact.companyName
                            },
                            "to": item.personalization ? item.personalization.to : null,
                            "from": item.personalization ? item.personalization.from : null,
                            "message": item.personalization ? item.personalization.message : null,
                            "orderItemCardAmounts": item.cardAmounts,
                            "fulfillmentTypeId": item.selectedFulfillmentType.id
                        }
                    ],
                    "hvtInfo": {
                        "firstName": item.hvtInfo.firstName,
                        "lastName": item.hvtInfo.lastName,
                        "companyName": item.hvtInfo.companyName,
                        "address1": item.hvtInfo.address1,
                        "address2": item.hvtInfo.address2,
                        "address3": item.hvtInfo.address3,
                        "address4": item.hvtInfo.address4,
                        "city": item.hvtInfo.city,
                        "stateOrProvince": item.hvtInfo.state.regionAbbreviation,
                        "zipOrPostalCode": item.hvtInfo.postalCode,
                        "country": item.hvtInfo.country.description,
                        "identificationSourceTypeId": item.hvtInfo.identificationSourceTypeId.id,
                        "identificationSourceIssuer": item.hvtInfo.identificationSourceIssuer,
                        "encryptedIdentificationNumber": item.hvtInfo.encryptedIdentificationNumber,
                        "cardUseTypeId": item.hvtInfo.cardUseTypeId.id,
                        "cardUseOther": item.hvtInfo.cardUseOther,
                        "emailAddress": item.hvtInfo.emailAddress
                    },
                    "supportPhoneNumber": siteVersionCulture.supportPhoneNumber
                };
            }
        }

		function ensureShippingContact() {
			var item = getItem();
			if (!item.shippingContact) {
				item.shippingContact = {};
			}
			saveItem(item);
		}
		function ensureBillingContact() {
			var item = getItem();
			if (!item.billingContact) {
				item.billingContact = {};
			}
			saveItem(item);
		}
		function ensurePaymentInformation() {
			var item = getItem();
			if (!item.paymentInformation) {
				item.paymentInformation = {};
			}
			saveItem(item);
		}
		function ensurePersonalization() {
			var item = getItem();
			if (!item.personalization) {
				item.personalization = {};
			}
			saveItem(item);
		}

		function getOptIn() {
			return getItem().optIn;
		}

		function setOptIn(optIn) {
			var item = getItem();
			item.optIn = optIn;
			saveItem(item);
		}

		function calculateFees(siteId, cardAmounts) {
			var deferred = $q.defer();
			notify.forHttp(api.order.calculateFees(siteId, cardAmounts), { startTranslationId: "PROGRESS_MESSAGES.CALCULATING_FEES" })
				.then(function(response) {
					setFees(response.totalFees);
					deferred.resolve(response.totalFees);
				},
				function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		}

		function getFees() {
			return getItem().fees;
		}

		function setFees(fees) {
			var item = getItem();
			item.fees = fees;
			saveItem(item);
		}

		function getDiscount() {
			return getItem().discount;
		}

		function setDiscount(discount) {
			var item = getItem();
			item.discount = discount;
			saveItem(item);
		}

		function getPersonalizationInfo() {
			return getItem().personalization;
		}

		function setPersonalizationInfo(personalizatinInfo) {
            var item = getItem();
            item.personalization = personalizatinInfo ? personalizatinInfo : null;
			saveItem(item);
		}

		function getBillingContact() {
			return getItem().billingContact;
		}

		function setBillingContact(billingContact) {
			var item = getItem();
			item.billingContact = billingContact;
			saveItem(item);
		}

		function getPaymentInformation() {
			return getItem().paymentInformation;
		}

		function setPaymentInformation(paymentInformation) {
			var item = getItem();
			item.paymentInformation = paymentInformation;
			saveItem(item);
		}

		function paymentAndBillingInfoValid() {
			var item = getItem();
			var paymentInformation = item.paymentInformation;
			var billingContact = item.billingContact;
			return paymentInformation && billingContact;
		}

		function shippingInfoValid() {
			var item = getItem();
			var selectedShippingMethod = item.selectedShippingMethod;
			var shippingContact = item.shippingContact;
			return shippingContact && (!fulfillmentTypeIsShipping(item) || selectedShippingMethod);
		}

		function cardProductsAndAmountsValid() {
			var item = getItem();
			var cardAmounts = item.cardAmounts;
			var selectedCardProduct = item.selectedCardProduct;
			return selectedCardProduct && angular.isArray(cardAmounts) && cardAmounts.length > 0 && cardAmounts[0].amount > 0;
		}

		function setSelectedShippingMethod(shippingMethod) {
			var item = getItem();
			item.selectedShippingMethod = shippingMethod;
			saveItem(item);
		}

		function getSelectedShippingMethod() {
			return getItem().selectedShippingMethod;
			}

		function setSelectedFulfillmentType(fulfillmentType) {
			var item = getItem();
			item.selectedFulfillmentType = fulfillmentType;
			saveItem(item);
		}

		function getSelectedFulfillmentType() {
			return getItem().selectedFulfillmentType;
		}

		function getRestrictShippingOptions() {
			return getItem().restrictShippingOptions;
		}

		function setRestrictShippingOptions(restrictShippingOptions) {
			var item = getItem();
			item.restrictShippingOptions = restrictShippingOptions;
			saveItem(item);
		}


		function setShippingContact(shippingContact) {
			var item = getItem();
			item.shippingContact = shippingContact;
			saveItem(item);
		}

		function getShippingContact() {
			return getItem().shippingContact;
		}

		function getDisbursementAccountId() {
            return getItem().disbursementAccountId;
		}

        function setDisbursementAccountId(disbursementAccountId) {
			var item = getItem();
            item.disbursementAccountId = disbursementAccountId;
            saveItem(item);
        }

		function getDisbursementReferenceNumber() {
            return getItem().disbursementReferenceNumber;
		}

        function setDisbursementReferenceNumber(reference) {
			var item = getItem();
            item.disbursementReferenceNumber = reference;
            saveItem(item);
        }

		function getCustomerOrderReference() {
			return getItem().customerOrderReference;
		}

		function setCustomerOrderReference(customerOrderReference) {
			var item = getItem();
			item.customerOrderReference = customerOrderReference;
			saveItem(item);
		}

		function getBankAccountName() {
            return getItem().bankAccountName;
		}

        function setBankAccountName(bankAccountName) {
			var item = getItem();
            item.bankAccountName = bankAccountName;
            saveItem(item);
		}

		function getAccountIdentifier() {
            return getItem().accountIdentifier;
		}

        function setAccountIdentifier(accountIdentifier) {
			var item = getItem();
            item.accountIdentifier = accountIdentifier;
            saveItem(item);
		}

		function getRoutingNumber() {
            return getItem().routingNumber;
		}

        function setRoutingNumber(routingNumber) {
			var item = getItem();
            item.routingNumber = routingNumber;
            saveItem(item);
		}

		function getAccountNumber() {
            return getItem().accountNumber;
		}

        function setAccountNumber(accountNumber) {
			var item = getItem();
            item.accountNumber = accountNumber;
            saveItem(item);
		}

		function getIban() {
			return getItem().iban;
		}

        function setIban(iban) {
			var item = getItem();
            item.iban = iban;
            saveItem(item);
		}

		function getBic() {
            return getItem().bic;
		}

		function setCustomerOrderReference(customerOrderReference) {
			var item = getItem();
			item.customerOrderReference = customerOrderReference;
			saveItem(item);
		}

		function getBankAccountName() {
            return getItem().bankAccountName;
		}

        function setBankAccountName(bankAccountName) {
			var item = getItem();
            item.bankAccountName = bankAccountName;
            saveItem(item);
		}

		function getRoutingNumber() {
            return getItem().routingNumber;
		}

        function setRoutingNumber(routingNumber) {
			var item = getItem();
            item.routingNumber = routingNumber;
            saveItem(item);
		}

		function getAccountNumber() {
            return getItem().accountNumber;
		}

        function setAccountNumber(accountNumber) {
			var item = getItem();
            item.accountNumber = accountNumber;
            saveItem(item);
		}

		function getIban() {
			return getItem().iban;
		}

        function setIban(iban) {
			var item = getItem();
            item.iban = iban;
            saveItem(item);
		}

		function getBic() {
            return getItem().bic;
		}

        function setBic(bic) {
			var item = getItem();
            item.bic = bic;
            saveItem(item);
        }

		function getItem() {
			var item = store.get(STORE_KEYS.ORDER_ITEM);
			if (!item) {
				store.set(STORE_KEYS.ORDER_ITEM, angular.copy({
					cardAmounts: [angular.copy(cardAmountMaster)],
					shippingContact: null,
					billingContact: null,
					paymentInformation: null,
					selectedCardProduct: null,
					selectedShippingMethod: null,
                    personalization: null,
                    hvtInfo: null,
                    isCorporate: false,
					restrictShippingOptions: null,
					disbursementAccountId: null,
					disbursementReferenceNumber: null,
					customerOrderReference: null,
					bankAccountName: null,
					accountIdentifier: null,
					routingNumber: null,
					accountNumber: null,
					iban: null,
					bic: null,
					captchaResponse: null
				}));
				return store.get(STORE_KEYS.ORDER_ITEM);
			}
			return item;
		}

		function hasItem() {
			return store.get(STORE_KEYS.ORDER_ITEM);
		}

		function saveItem(item) {
			store.set(STORE_KEYS.ORDER_ITEM, item);
		}

		function setCardAmounts(cardAmounts) {
			var item = getItem();
			item.cardAmounts = cardAmounts;
			saveItem(item);
		}

		function getCardAmounts() {
			return getItem().cardAmounts;
		}

		function cardAmountsValid(cardAmounts) {
			if (!angular.isArray(cardAmounts)) {
				cardAmounts = getItem().cardAmounts;
			}
			return angular.isArray(cardAmounts)
				&& (cardAmounts.length > 0)
				&& _.every(cardAmounts, function (cardAmount) {
				return cardAmount.amount && cardAmount.amount > 0;
			});
		}

		function removeCardAmount(cardAmount) {
			if (cardAmount) {
				var item = getItem();
				angular.copy(_.reject(item.cardAmounts, function (aCardAmount) {
					return cardAmount.$$hashKey === aCardAmount.$$hashKey;
				}), item.cardAmounts);
				saveItem(item);
			}
		}

		function haveCardAmounts() {
			var cardAmounts = getItem().cardAmounts;
			return angular.isArray(cardAmounts) && cardAmounts.length > 0;
		}

		function setCardProduct(cardProduct) {
			if (cardProduct) {
				var item = getItem();
				item.selectedCardProduct = cardProduct;
				saveItem(item);
			}
        }

		function getCardProduct() {
			return getItem().selectedCardProduct;
        }

        function setSalesType(isCorporate) {
            var item = getItem();
            item.isCorporate = isCorporate;
            saveItem(item);
        }

        function setHighValueTransactionInfo(hvtInfo) {
            var item = getItem();
            item.hvtInfo = hvtInfo;
            saveItem(item);
        }

        function getHighValueTransactionInfo() {
            return getItem().hvtInfo;
        }

		function addCardAmount() {
			var item = getItem();
			item.cardAmounts.push(angular.copy(cardAmountMaster));
			saveItem(item);
		}

		function getOrderTotals(item) {
			if(!item) {
				item = getItem();
			}

			var cardAmountTotal = _.reduce(item.cardAmounts, function(amountMemo, amount) {
				return amountMemo + (amount.quantity*amount.amount) || 0;
			}, 0);
			var shippingTotal = (item.selectedShippingMethod && fulfillmentTypeIsShipping(item)) ? item.selectedShippingMethod.cost: 0;

			var fees = item.fees || 0;
			var discount = item.isCorporate ? (item.discount || 0) : 0;

			return {
				shipping: shippingTotal,
				cardAmount: cardAmountTotal,
				fees: fees,
				discount:discount,
				orderTotal: Math.max(+((shippingTotal + cardAmountTotal + fees) - discount).toFixed(2), 0.00)
			};
		}

		function calculateDiscount(siteId, siteVersionId, cardAmounts) {
			var deferred = $q.defer();
			notify.forHttp(api.order.calculateDiscount(siteId, siteVersionId, cardAmounts), { startTranslationId: "PROGRESS_MESSAGES.CALCULATING_DISCOUNT" })
				.then(function(response) {
					setDiscount(response.totalDiscount);
					deferred.resolve(response.totalDiscount);
				},
				function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		}

		function fulfillmentTypeIsShipping(item) {
			return item && item.selectedFulfillmentType && item.selectedFulfillmentType.name === SHIPPING_FULFILLMENT_NAME;
		}

		function reset() {
			store.remove(STORE_KEYS.ORDER_ITEM);
		}

		function setPurchaserInfo(){
			var item = store.get(STORE_KEYS.SUBMITTED_ORDER);
			if (item) {
				store.set(STORE_KEYS.ORDER_ITEM, angular.copy({
					cardAmounts: [angular.copy(cardAmountMaster)],
					shippingContact: angular.copy(item.shippingContact),
					billingContact: angular.copy(item.billingContact),
					paymentInformation: null,
					selectedCardProduct: null,
					selectedShippingMethod: null,
                    personalization: null,
                    hvtInfo: angular.copy(item.hvtInfo),
                    isCorporate: angular.copy(item.isCorporate)
				}));
			}
		}
	}
}());