(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("customerService", customerServiceFactory);

    customerServiceFactory.$inject = ["api", "appConfig", ];

    function customerServiceFactory(api, appConfig) {        

        return {
            searchCustomers: searchCustomers,
            searchCustomerCards: searchCustomerCards,
            updateCustomer: updateCustomer
        };

        function searchCustomers(searchCustomerModel) {

            return api.customer.searchCustomers(appConfig.selectedMerchantGroup().uniqueTag,
                searchCustomerModel.isOrganization,
                searchCustomerModel.name,
                searchCustomerModel.firstName,
                searchCustomerModel.lastName,
                searchCustomerModel.phoneNumber);
        }

        function searchCustomerCards(isOrganization, customerId) {
            return api.customer.searchCustomerCards(isOrganization, customerId);
        }

        function updateCustomer(updateModel) {
            return api.customer.updateCustomer(appConfig.selectedMerchantGroup().uniqueTag, updateModel);
        }
    }
} ());