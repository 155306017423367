(function () {
    "use strict";

	angular.module("cardFrontConsumer")
    .config(configSalesHome);

    configSalesHome.$inject = ["$routeProvider", "NAV_URLS"];
    function configSalesHome($routeProvider, NAV_URLS) {

                $routeProvider
                    .when(NAV_URLS.SALES_HOME, {
                        templateUrl: "areas/sales/sales-home/sales-home.html",
                	controller: "SalesHome",
                	controllerAs: "model",
                    titleTranslationId: "SALES_HOME.TITLE",
                	resolve: {
                		 site: resolveSite
                	}

                })
                .otherwise({ redirectTo: NAV_URLS.HOME });
    }

    resolveSite.$inject = ["siteService"];

    function resolveSite(siteService) {
    	return siteService.get();
    }


}());