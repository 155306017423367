(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(configModule);

	configModule.$inject = ["$provide"];

	function configModule($provide) {

		$provide.decorator("lookupValues", decorateLookupValues);

		decorateLookupValues.$inject = ["$delegate", "api", "notify"];

		function decorateLookupValues($delegate, api, notify) {

			var fulfullmentTypesPromise;

			$delegate.getFulfillmentTypes = function () {
				if (!fulfullmentTypesPromise) {
					fulfullmentTypesPromise = notify.forHttp(api.lookupValues.getFulfillmentTypes(), { startTranslationId: "PROGRESS_MESSAGES.GETTING_FULFILLMENT_TYPES" });
				}
				return fulfullmentTypesPromise;
			};


			return $delegate;
		}
	}
}());