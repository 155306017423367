(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.privacyPolicy")
        .controller("PrivacyPolicy", PrivacyPolicy);

	PrivacyPolicy.$inject = [];

	function PrivacyPolicy() {
		var model = this
		;
	}
}());