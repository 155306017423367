(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.sales.order-details")
        .controller("OrderDetails", OrderDetails);

    OrderDetails.$inject = ["$scope", "navigate", "shoppingCart", "site", "CONTACT_FIELDS", "notify", "api", "lookupValues", "SHIPPING_FULFILLMENT_NAME", "EVENTS", "FULFILLMENT_TYPES", "HVT_CORPORATE_THRESHOLD_AMOUNT"];

    function OrderDetails($scope, navigate, shoppingCart, site, CONTACT_FIELDS, notify, api, lookupValues, SHIPPING_FULFILLMENT_NAME, EVENTS, FULFILLMENT_TYPES, HVT_CORPORATE_THRESHOLD_AMOUNT) {
        var model = this,
            shippingContactMaster,
            billingContactMaster,
            personalizationMaster,
            highValueTransactionInfoMaster,
            shippingFulfillmentType;

        model.site = site;
        shoppingCart.ensure.billingContact();
        model.orderItem = shoppingCart.item();
        model.currencySymbol = site.currencySymbol;
        model.saveAndContinue = saveAndContinue;
        model.paymentInformation = model.orderItem.paymentInformation;
        model.termsAgreed = model.orderItem.termsAgreed;
        model.optIn = model.orderItem.optIn;
		model.setBillingContactInformation = setBillingContactInformation;
	    model.setHvtContactInformation = setHvtContactInformation;
        model.personalization = model.orderItem.personalization;
        model.requiredFields = [
            CONTACT_FIELDS.FIRST_NAME,
            CONTACT_FIELDS.LAST_NAME,
            CONTACT_FIELDS.ADDRESS_ONE,
            CONTACT_FIELDS.CITY,
            CONTACT_FIELDS.STATE,
            CONTACT_FIELDS.POSTAL_CODE,
            CONTACT_FIELDS.COUNTRY,
            CONTACT_FIELDS.EMAIL,
            CONTACT_FIELDS.PHONE,
            CONTACT_FIELDS.BUSINESS_NAME,
            CONTACT_FIELDS.COMMERCIAL_REGISTRATION_NUMBER,
            CONTACT_FIELDS.PEP_ANSWER
        ];
        model.isCorporateSale = model.orderItem.isCorporate;
        model.totals = shoppingCart.getOrderTotals(model.orderItem);
        model.applyRegionRestrictions = applyRegionRestrictions;
		model.selectedFulfillmentTypeId = FULFILLMENT_TYPES.SHIPPING.ID;
        model.showShippingRestrictionMessage = showShippingRestrictionMessage;
        model.corporateInvoicingEnabled = site.corporateInvoicingEnabled;
        model.hvtCorporateThresholdAmount = HVT_CORPORATE_THRESHOLD_AMOUNT;
        model.commercialRegistrationNumberEnabled = site.commercialRegistrationNumberEnabled;
        model.isPEP = undefined;
        model.isRequired = isRequired;
        model.CONTACT_FIELDS = CONTACT_FIELDS;
        model.collectEmail = collectEmail;

        if (!model.orderItem) {
            navigate.toSalesHome();
        }
        if (site.shipToCountryRestrictionEnabled) {
            model.countriesEnabled = site.shipToCountries;
        }

        init();

        function init() {
            model.shippingContact = angular.copy(model.orderItem.shippingContact);
            createShippingContactMaster(model.shippingContact);

            model.billingContact = angular.copy(model.orderItem.billingContact);
            createBillingContactMaster(model.billingContact);

            model.hvtInfo = angular.copy(model.orderItem.hvtInfo);
            createHighValueTransactionInfoMaster(model.hvtInfo);

            model.personalization = angular.copy(model.orderItem.personalization);

            lookupValues.getFulfillmentTypes()
                .then(function (data) {
                    model.fulfillmentTypes = data;

                    initModel();
                });

        }

	    $scope.$on(EVENTS.FULFILLMENT_INFO_CHANGED,
		    function() {
			    if (model.orderItem.selectedFulfillmentType) {
				    model.selectedFulfillmentTypeId = model.orderItem.selectedFulfillmentType.id;
			    }

			    model.selectedFulfillmentTypeIsShipping = model.orderItem.selectedFulfillmentType &&
				    model.orderItem.selectedFulfillmentType.id === FULFILLMENT_TYPES.SHIPPING.ID;
			    initModel();
		    });

        function createHighValueTransactionInfoMaster(sourceHvtInfo) {
            if (sourceHvtInfo) {
                highValueTransactionInfoMaster = angular.copy(sourceHvtInfo);
                highValueTransactionInfoMaster.country = angular.copy(sourceHvtInfo.country);
            }
        }

        function createShippingContactMaster(sourceShippingContact) {
            if (sourceShippingContact) {
                shippingContactMaster = angular.copy(sourceShippingContact);
                shippingContactMaster.country = angular.copy(sourceShippingContact.country);
            }
        }

        function createBillingContactMaster(sourceBillingContact) {
            if (sourceBillingContact) {
                billingContactMaster = angular.copy(sourceBillingContact);
                billingContactMaster.country = angular.copy(sourceBillingContact.country);
            }
        }

        function setBillingContactInformation() {
            model.billingContact = angular.copy(model.shippingContact);
		}

		function setHvtContactInformation() {
			ensureShippingContact();
			model.hvtInfo = angular.copy(model.shippingContact);
			model.hvtInfo.emailAddress = angular.copy(model.shippingContact.email);
		}

		function ensureShippingContact()
	    {
		    if (!model.shippingContact) {
			    model.shippingContact = {};
		    }
        }

        function applyRegionRestrictions() {
            return model.site.shippingRestrictionsEnabled && shippingFulfillmentType && shippingFulfillmentType.id === model.selectedFulfillmentTypeId;
        }

        function initModel() {
            var item = shoppingCart.item();

            model.restrictShippingOptions = item.restrictShippingOptions;

            shippingFulfillmentType = _.find(model.fulfillmentTypes, function (fulfillmentType) {
                return fulfillmentType.name === SHIPPING_FULFILLMENT_NAME;
            });

            setEnabledCountries();
        }

        function setEnabledCountries() {
            var countriesEnabled = site.shipToCountries && site.shipToCountryRestrictionEnabled && model.selectedFulfillmentTypeId === FULFILLMENT_TYPES.SHIPPING.ID
                ? site.shipToCountries : undefined;
            model.countriesEnabled = countriesEnabled;

        }

        function saveAndContinue() {
            if (model.orderItem.isCorporate && !model.orderItem.disbursementReferenceNumber) {
                return notify.forHttp(api.order.generateDisbursementAccount(model.site.corporateOrderBankAccount.corporateOrderBankAccountId, model.site.id), { startTranslationId: "PROGRESS_MESSAGES.CREATING_DISBURSEMENT_ACCOUNT" })
                    .then(function (data) {
                        shoppingCart.setDisbursementAccountId(data.disbursementAccountId);
                        shoppingCart.setDisbursementReferenceNumber(data.referenceNumber);
                        shoppingCart.setCustomerOrderReference(data.customerOrderReference);
                        shoppingCart.setBankAccountName(data.bankAccountName);
                        shoppingCart.setAccountIdentifier(data.accountIdentifier);
                        shoppingCart.setRoutingNumber(data.routingNumber);
                        shoppingCart.setAccountNumber(data.accountNumber);
                        shoppingCart.setIban(data.iban);
                        shoppingCart.setBic(data.bic);
                        shoppingCart.setShippingContact(model.shippingContact);
                        shoppingCart.setBillingContact(model.billingContact);
                        shoppingCart.setOptIn(model.optIn);
                        shoppingCart.setTermsAgreed(model.termsAgreed);
                        shoppingCart.setPersonalizationInfo(model.personalization);
                        personalizationMaster = angular.copy(model.personalization);
                        shoppingCart.setHighValueTransactionInfo(model.hvtInfo);
                        navigate.toReviewOrder();
                    });
            } else {
                shoppingCart.setShippingContact(model.shippingContact);
                shoppingCart.setOptIn(model.optIn);
                shoppingCart.setTermsAgreed(model.termsAgreed);
                shoppingCart.setPersonalizationInfo(model.personalization);
                personalizationMaster = angular.copy(model.personalization);
                shoppingCart.setBillingContact(model.billingContact);
                shoppingCart.setPaymentInformation(model.paymentInformation);
                navigate.toReviewOrder();
            }
		}


	    function showShippingRestrictionMessage(countryDescription) {
		    return model.shippingContact && model.shippingContact.country && model.shippingContact.country.description === countryDescription && model.applyRegionRestrictions();
	    }

        function isRequired(fieldName) {
			return model.requiredFields && _.find(model.requiredFields, function(field) {
				return field === fieldName;
			});
		}

        function collectEmail(shouldCollectEmail) {
            if (model.isCorporateSale)
                return model.isCorporateSale;

            return shouldCollectEmail
        }
    }
}());