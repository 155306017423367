(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
		.directive("sfCardDesign", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				site: "=",
				selectedCardProduct: "="
			},
			controller: CardDesignController,
			controllerAs: "model",
			templateUrl: "areas/sales/directives/cardDesign.directive.html"
		};
	}

	CardDesignController.$inject = ["$scope", "shoppingCart", "$rootScope", "$routeParams"];

	function CardDesignController($scope, shoppingCart, $rootScope, $routeParams) {
		var model = this;
		model.site = $scope.site;
        model.selectCardProduct = selectCardProduct;
		model.isSelectedCardProduct = isSelectedCardProduct;
		model.subHeaderText = $rootScope.consumerSalesContent.subHeaderText;

		checkSelectedCardProduct();

		function selectCardProduct(cardProduct) {
            shoppingCart.setCardProduct(cardProduct);
            if ($routeParams.isCorporate) {
                shoppingCart.setSalesType($routeParams.isCorporate);
            } else { shoppingCart.setSalesType(false); }
			$scope.selectedCardProduct = cardProduct;
		}

		function isSelectedCardProduct(cardProduct) {
			var selectedCardProduct = shoppingCart.getCardProduct();
			return selectedCardProduct && selectedCardProduct.id === cardProduct.id;
		}

		function checkSelectedCardProduct() {
			var selectedCardProduct = shoppingCart.getCardProduct();
			if (selectedCardProduct) {
				$scope.selectedCardProduct = selectedCardProduct;
			}
		}

		$rootScope.$watch("consumerSalesContent", function(consumerSalesContent) {
			if (!consumerSalesContent)
				return;

			model.subHeaderText = $rootScope.consumerSalesContent.subHeaderText;
		});
	}
}());
