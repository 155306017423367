(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.cardholderAgreement")
        .controller("CardholderAgreement", CardholderAgreement);

    CardholderAgreement.$inject = ["$scope", "$sce"];

    function CardholderAgreement($scope, $sce) {
        var model = this;                

        $scope.$watch("content",
            function(newContent) {
                if (newContent) {
                    model.cardHolderAgreementText = $sce.trustAsHtml($scope.content.cardHolderAgreementText);
                }
            });
    }
}());