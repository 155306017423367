(function () {
    "use strict";

    angular.module("cardFrontConsumer.areas.check-balance.register-card")
        .controller("RegisterCard", RegisterCard);

    RegisterCard.$inject = ["$scope", "site", "CONTACT_FIELDS", "$sce", "navigate", "registerCardService", "lookupValues", "cardService", "store", "STORE_KEYS"];

    function RegisterCard($scope, site, CONTACT_FIELDS, $sce, navigate, registerCardService, lookupValues, cardService, store, STORE_KEYS) {
        var model = this;
        model.site = site;
        model.cardRegistrationText = $sce.trustAsHtml($scope.checkBalanceContent.cardRegistrationText);
        model.optInEnabled = site.optInEnabled;
        model.optInMessage = $scope.checkBalanceContent.optInMessage;
        model.card = $scope.getCard();
        model.saveAndContinue = saveAndContinue;
        model.requiredFields = [
            CONTACT_FIELDS.FIRST_NAME,
            CONTACT_FIELDS.LAST_NAME,
            CONTACT_FIELDS.ADDRESS_ONE,
            CONTACT_FIELDS.CITY,
            CONTACT_FIELDS.STATE,
            CONTACT_FIELDS.POSTAL_CODE,
            CONTACT_FIELDS.COUNTRY,
            CONTACT_FIELDS.EMAIL,
            CONTACT_FIELDS.PHONE
        ];
        model.navigatePrevious = navigatePrevious;
        model.contact = getContact();
        model.modalShown = false;
        model.showModal = showModal;
        model.cultureName = $scope.$root.currentLanguage().language;

        function getContact() {
            if (angular.isDefined(model.card) && angular.isDefined(model.card.registeredCustomer)) {
                return {
                    firstName: model.card.registeredCustomer.firstName,
                        lastName:model.card.registeredCustomer.lastName,
                        email:model.card.registeredCustomer.email,
                        phone:model.card.registeredCustomer.phone,
                        phoneExt:model.card.registeredCustomer.phoneExt,
                        fax:model.card.registeredCustomer.fax,
						faxExt: model.card.registeredCustomer.faxExt,						
                        address1:model.card.registeredCustomer.address1,
                        address2:model.card.registeredCustomer.address2,
                        city:model.card.registeredCustomer.city,
                        country: model.card.registeredCustomer.country,
                        state: { regionAbbreviation: model.card.registeredCustomer.stateOrProvince },
                        postalCode: model.card.registeredCustomer.postalCode,
                        marketingOptIn: model.card.registeredCustomer.marketingOptIn
                };
            }
            return {
                country: "",
                state: { regionAbbreviation: "" }
            };
        }

        function saveAndContinue() {            
            registerCardService.register(model.card, angular.copy(model.contact)).then(function(data) {
                if (data.succeeded) {
                    var securityCode = store.get(STORE_KEYS.SECURITY_CODE);
                    cardService.lookupByPrintText(model.card.printText, securityCode, model.card.programUniqueTag, model.cultureName).then(function (data) {
                        if (data.succeeded) {
                            handleSuccess(data);   
                            showModal();   
                        }                      
                    });                                        
                }                
            });
        }

        function handleSuccess(data) {
            var card = data.card;
            if (card.isRegistered && card.canRegister) {
                lookupValues.getCountry(data.card.registeredCustomer.country).then(function(countryData) {
                    card.registeredCustomer.country = countryData;   
                    $scope.setCard(card);                                     
                });
            } else {
                $scope.setCard(card);                          
            }
        }        

        function getRegisteredCustomer() {
            return {
                id: model.card.registeredCustomer.id,
                firstName: model.contact.firstName,
                lastName: model.contact.lastName,
                email: model.contact.email,
                phone: model.contact.phone,
                phoneExt: model.contact.phoneExt,
                fax: model.contact.fax,
				faxExt: model.contact.faxExt,				
                address1: model.contact.address1,
                address2: model.contact.address2,
                city: model.contact.city,
                country: model.contact.country,
                stateOrProvince: model.contact.state.regionAbbreviation,
                postalCode: model.contact.postalCode,
                marketingOptIn: model.contact.marketingOptIn
            }
        }

        function navigatePrevious() {
            navigate.toCheckBalanceHome();
        }


        function showModal() {
            model.modalShown = !model.modalShown;
        }

        $scope.$watch("checkBalanceContent",
            function (newcheckBalanceContent) {
                if (newcheckBalanceContent) {
					model.cardRegistrationText = $sce.trustAsHtml($scope.checkBalanceContent.cardRegistrationText);
	                model.optInMessage = $scope.checkBalanceContent.optInMessage;
                }
            });
    }
}());