(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.home")
        .controller("Home", Home);

	Home.$inject = ["$scope", "$sce", "site", "navigate", "NAV_URLS", "$rootScope", "shoppingCart", "$location", "$route", "$translate"];

	function Home($scope, $sce, site, navigate, NAV_URLS, $rootScope, shoppingCart, $location, $route, $translate) {
        var model = this;
        model.site = site;
        model.siteText = $sce.trustAsHtml($scope.content.siteText);
        model.homeHeaderText = $sce.trustAsHtml($scope.content.homeHeaderText);
        model.homeBodyText = $sce.trustAsHtml($scope.content.homeBodyText);
        model.homeConsumerHeaderText = $sce.trustAsHtml($scope.content.homeConsumerHeaderText);
        model.homeConsumerBodyText = $sce.trustAsHtml($scope.content.homeConsumerBodyText);
        model.homeCorporateHeaderText = $sce.trustAsHtml($scope.content.homeCorporateHeaderText);
        model.homeCorporateBodyText = $sce.trustAsHtml($scope.content.homeCorporateBodyText);
        model.homeCheckBalanceHeaderText = $sce.trustAsHtml($scope.content.homeCheckBalanceHeaderText);
		model.homeCheckBalanceBodyText = $sce.trustAsHtml($scope.content.homeCheckBalanceBodyText);
		model.heroBannerUrl = encodeURIComponent(site.heroBannerUrl);			
		model.setCard = $scope.setCard;
		model.visited = false;
		model.content = $rootScope;

        $scope.NAV_URLS = NAV_URLS;

        init();

		function init() {
			shoppingCart.reset();
			$rootScope.removeCard();
			$rootScope.clearCardLookup();
			if (site.consumerSalesEnabled && !site.checkBalanceEnabled && !site.corporateSalesEnabled) {						
                navigate.toSalesHome();
			} else if (!site.consumerSalesEnabled && !site.corporateSalesEnabled && site.checkBalanceEnabled) {
                navigate.toCheckBalanceHome();
			} else if (site.corporateSalesEnabled && !site.consumerSalesEnabled && !site.checkBalanceEnabled) {				
				$location.path(NAV_URLS.SALES_HOME).search({ isCorporate: "true" });
			}
		}
		
		//Warn users that they'll lose their data when navigating home
		$rootScope.$on('$locationChangeStart', function (event) {
			if (isOnlyOneEnabled()) return;
			var route_object = ($route.routes[$location.path()]);
			var hasItem = shoppingCart.hasItem();
			var hasCard = $rootScope.getCard();
			if (route_object.originalPath === NAV_URLS.HOME && (hasItem || hasCard)) {				
				if (!model.visited) {					
					if (!event.defaultPrevented && !confirm($translate.instant('GLOBAL.NAVIGATE_HOME_CONFIRMATION'))) {
						event.preventDefault();
						model.visited = false;
					} else {
						model.visited = true;						
					}
				}
			}
		});

		function isOnlyOneEnabled() {
			return (site.checkBalanceEnabled && !site.sellCardsEnabled) ||
			(!site.checkBalanceEnabled &&
				site.sellCardsEnabled &&
				((site.consumerSalesEnabled && !site.corporateSalesEnabled) ||
					(!site.consumerSalesEnabled && site.corporateSalesEnabled)));
		}

        $scope.$watch("content",
            function (newContent) {
                if (newContent) {
					model.siteText = $sce.trustAsHtml($scope.content.siteText);
	                model.homeHeaderText = $sce.trustAsHtml($scope.content.homeHeaderText);
	                model.homeBodyText = $sce.trustAsHtml($scope.content.homeBodyText);
	                model.homeConsumerHeaderText = $sce.trustAsHtml($scope.content.homeConsumerHeaderText);
	                model.homeConsumerBodyText = $sce.trustAsHtml($scope.content.homeConsumerBodyText);
	                model.homeCorporateHeaderText = $sce.trustAsHtml($scope.content.homeCorporateHeaderText);
	                model.homeCorporateBodyText = $sce.trustAsHtml($scope.content.homeCorporateBodyText);
	                model.homeCheckBalanceHeaderText = $sce.trustAsHtml($scope.content.homeCheckBalanceHeaderText);
	                model.homeCheckBalanceBodyText = $sce.trustAsHtml($scope.content.homeCheckBalanceBodyText);
                }
            });
    }
}());