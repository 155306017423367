(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.sales-home")
        .controller("SalesHome", SalesHome);

    SalesHome.$inject = ["$scope", "site", "shoppingCart", "navigate"];

    function SalesHome($scope, site, shoppingCart, navigate) {
		var model = this;
		model.site = site;
		model.selectedCardProduct = undefined;
		model.navigateToOrderDetails = navigateToOrderDetails;
        model.cardAmounts = shoppingCart.getCardAmounts();
        model.cancel = cancel;

		function navigateToOrderDetails() {
			navigate.toOrderDetails();
        }

        function cancel() {
            shoppingCart.reset();
            model.selectedCardProduct = undefined;
        }
	}
}());