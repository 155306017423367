(function () {
	"use strict";

    angular.module("cardFrontConsumer.areas.sales.directives")
		.directive("sfOrderSummary", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				orderItem: "=",
				editVisible: "=?",
				content: "=",
				currencySymbol: "=",
                site: "=",
                isTrailingSymbol: "=",
                currencySeparators: "="
			},
			controller: Controller,
			controllerAs: "model",
			templateUrl: "areas/sales/directives/orderSummary.directive.html"
		};
	}

    Controller.$inject = ["$scope", "navigate", "shoppingCart", "$rootScope", "FULFILLMENT_TYPES", "EVENTS"];

	function Controller($scope, navigate, shoppingCart, $rootScope, FULFILLMENT_TYPES, EVENTS) {
        var model = this;

		model.site = $scope.site;
		model.editVisible = $scope.editVisible;
		model.orderItem = $scope.orderItem;
        model.editBillingPayment = editBillingPayment;
        model.cardAmounts = shoppingCart.getCardAmounts();
		model.onSitePickupEnabled = model.site.onSitePickupEnabled;
		model.onSitePickupInstructions = $scope.content.onSitePickUpInstructions;
        model.shippingMethodRestrictionsEnabled = model.site.shippingMethodRestrictionsEnabled;
        model.shippingMethodRestrictionQuestion = $rootScope.consumerSalesContent.shippingMethodRestrictionQuestion;
        model.shippingMethods = model.site.shippingMethods;
        model.totals = shoppingCart.getOrderTotals(model.orderItem);
        model.selectedFulfillmentTypeId = FULFILLMENT_TYPES.ON_SITE_PICKUP.ID;


        $scope.$on(EVENTS.FULFILLMENT_INFO_CHANGED, function () {
			model.totals = shoppingCart.getOrderTotals(model.orderItem);
        });

		function editBillingPayment() {
			navigate.toCheckout();
        }

	}
}());
