(function() {
    "use strict";

    angular.module("cardFrontConsumer.areas.directives")
        .directive("sfCheckMyBalance", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                isModal: "=",
                closeModal: "=",
                setCard: "=",
                cardLookup: "=",
                site: "=",
                content: "="
            },
            controller: CheckMyBalanceController,
            controllerAs: "model",
            templateUrl: "areas/directives/checkMyBalance.directive.html"
        }
    }

    CheckMyBalanceController.$inject = ["$scope", "navigate", "cardService", "lookupValues", "store", "STORE_KEYS","$rootScope"];

    function CheckMyBalanceController($scope, navigate, cardService, lookupValues, store, STORE_KEYS, $rootScope) {
        var model = this;
        model.site = $scope.site;
        model.checkMyBalance = checkMyBalance;
        model.hasErrorAlert = false;    
        model.isModal = $scope.isModal;
        model.clearError = clearError;
        model.cardLookup = $scope.cardLookup;        
        model.content = $scope.content;
        model.checkBalanceContent = $rootScope.checkBalanceContent;
        model.cultureName = $scope.$root.currentLanguage().language;

        function checkMyBalance() {
            cardService.lookupByPrintText(model.cardLookup.printText, 
                model.cardLookup.securityCode, 
                model.cardLookup.programUniqueTag,
                model.cultureName, 
                model.isModal).then(function (data) {
                    if (data.succeeded) {
                        handleSuccess(data);
                        store.set(STORE_KEYS.SECURITY_CODE, model.cardLookup.securityCode);
                    } else {
                        handleFailure(data);
                    }
            });
        }

        function clearError() {
            model.hasErrorAlert = false;            
            if (angular.isDefined(model.cardLookup))
                    model.cardLookup.errorMessage = "";            
        }

        function handleFailure(data) {
            model.hasErrorAlert = true;
            model.cardLookup.errorMessage = data.message;            
        }

        function handleSuccess(data) {
            var card = data.card;
            if (model.isModal) {
                $scope.closeModal();                
            }
            if (card.isRegistered && card.canRegister) {
                lookupValues.getCountry(data.card.registeredCustomer.country).then(function(countryData) {
                    card.registeredCustomer.country = countryData;   
                    $scope.setCard(card);
                    navigate.toCheckBalanceHome();                     
                });
            } else {
                $scope.setCard(card);
                navigate.toCheckBalanceHome();                
            }
        }

        $rootScope.$watch(function(){return $rootScope.checkBalanceContent;},
            function (newContent) {
                model.checkBalanceContent = $rootScope.checkBalanceContent;
            },true);
    }    
}());