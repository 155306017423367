(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.faqs")
        .controller("Faqs", Faqs);

	Faqs.$inject = ["$scope", "navigate"];

	function Faqs($scope, navigate) {
		var model = this;

		if (!$scope.hasFaq) {
			navigate.toHome();
		}

		model.faq = $scope.content.faq;

		$scope.$watch("content",
			function (newContent) {
				if (newContent) {
					model.faq = $scope.content.faq;
				}
			});
	}
}());