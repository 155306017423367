(function () {
	"use strict";

	angular.module("cardFrontConsumer")
        .config(configStore);

	configStore.$inject = ["storeProvider"];
	function configStore(storeProvider) {
		storeProvider.setStore("sessionStorage");
	}
}());