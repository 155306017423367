(function () {
	"use strict";

	angular.module("cardFrontConsumer.areas.termsOfUse")
        .controller("TermsOfUse", TermsOfUse);

	TermsOfUse.$inject = [];

	function TermsOfUse() {
		var model = this
		;
	}
}());