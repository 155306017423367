(function() {
    "use strict";

    angular.module("cardFrontConsumer.services")
        .factory("cardPinService", configFactory);

    configFactory.$inject = ["$rootScope", "api", "notify"];

    function configFactory($rootScope, api, notify) {
        return {
            setPin: setPin,
            changePin: changePin
        };

        function setPin(printText, pinChangeModel) {
            return notify.forHttp(api.cards.setPin(printText, pinChangeModel), { startTranslationId: "PROGRESS_MESSAGES.CHANGING_PIN" });
        }

        function changePin(printText, pinChangeModel) {
            return notify.forHttp(api.cards.changePin(printText, pinChangeModel), { startTranslationId: "PROGRESS_MESSAGES.CHANGING_PIN" });
        }        
    }
}());